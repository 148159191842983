import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {
  features: any;
  productImg: any;
  productName: any;
  selectedProduct: any;
  constructor() { }
  ngOnInit() {
    this.productName = ['Restaurant Floor Plan', 'Shift Reporting & Analytics', 'Reservation Timeline'];
    this.productImg = [{content: 'Create a customised layout and emulate your restaurant floor plan on the reservation system.' ,
                       image: 'restaurant-floor-plan',
                       alttitle: 'Customizable floor plan on the reservation system',
                       alttext: 'Screenshot of customizable restaurant floor plan on the reservation system'},
                       {content: 'Get shift-wise reports and analytics on reservation metrics and business performance.',
                        image: 'reservation-analytics-shift-reporting',
                        alttitle: 'Get reservation analytics ',
                        alttext: 'Screenshot of reservation analytics and shift-wise reports'},
                       {content: 'Keep track of table-wise reservations with a timeline representation.',
                        image: 'reservation-timeline',
                        alttitle: 'Track reservations on a timeline',
                        alttext: 'Screenshot of table reservations represented on a timeline'}];
    this.selectedProduct = 1;
    this.features = [{cardImage: 'restaurant-table-management',
                    cardTitle: 'table management',
                    alttitle: 'Manage restaurant tables in real-time',
                    alttext: 'Icon for real-time restaurant table management',
                    cardContent: 'Overview the status of all your restaurant tables in real-time and assign them to customers based on reservations, walk-ins or their profile.'},
                    {cardImage: 'waitlist-customers',
                    cardTitle: 'queue management',
                    alttitle: 'Manage customer waitlists',
                    alttext: 'Icon for manage customer waitlist as per table statuses',
                    cardContent: 'Waitlist customers, derive estimate waiting times and seat customers based on the real-time status of tables.'},
                    {cardImage: 'comprehensive-customer-profiles',
                    cardTitle: 'customer profiles',
                    alttitle: 'Record customer details in comprehensive profiles',
                    alttext: 'Icon for record customer details in comprehensive profiles',
                    cardContent: 'Maintain comprehensive customer data in smart profiles that keep history, create and mark tags, record preferences and take notes.'}];
  }
  onSelectProduct(index) {
    let element = document.getElementById('myimg');
    element.classList.add('transition');
    this.selectedProduct = index;
    setTimeout(() => {
      element.classList.remove('transition');
   }, 1000);
  }
}
