import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import {environment} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  private url: string = environment.WEB_API;
  constructor(public http: HttpClient) { }
  submitInquiry(body) {
   return this.http.post(this.url + '/web/api/v2/merchant/contact', body);
  }
}
