import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private url: string = environment.WEB_API;
  private dashboardUrl: string = environment.LOGIN_API;
  constructor(public http: HttpClient) { }
    getReport(auth, params) {
      const headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': auth
      });
      return this.http.get(this.url + '/web/api/v2/reports/reports-data', {headers, params});
      // return this.http.get(this.dashboardUrl + '/v2/merchant_period_vise_report', {headers, params});
    }
    getCampaignReport(auth_token , params){
      const headers = new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': auth_token
      });
    return this.http.get(this.dashboardUrl + '/v2/campaign_report', {headers,params});
    }
  }
