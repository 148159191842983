import { Component, OnInit} from '@angular/core';
import { NavbarserviceService} from '../navbar/navbarservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EBillService} from './e-bill.service';
import { FeedbackService} from './../web-feedback/feedback.service';
import { PopupTaxesComponent} from './popup-taxes/popup-taxes.component';
import { MatDialog } from "@angular/material";
import * as _ from 'lodash';
// import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-e-bill',
  templateUrl: './e-bill.component.html',
  styleUrls: ['./e-bill.component.css']
})
export class EBillComponent implements OnInit {
  billJson:any;
  billNumber:any
  selectedScore:any;
  slug:any;
  subTotal:any;
  totalDiscount:any;
  feedbackStatus:any='Submit your feedback';
  feedback:any;
  showFeedback:boolean = false;
  showButton:boolean = false;
  order_type_text:any={
    'delivery':'Delivery',
    'take_away':'Pickup',
    'dine_in':'Dine in'
  };
  is_order_completed:boolean=false;
  http: any;
  constructor( private nav: NavbarserviceService,
               private route: ActivatedRoute,
               private router: Router,
               private eService:EBillService,
               private feedbackService:FeedbackService,
               public dialog: MatDialog,
              ) {}

  ngOnInit() {
    this.nav.visible = false;
    this.route.params.forEach((urlParams) => {
      this.slug = urlParams['sulg'];
      this.billNumber = urlParams['bill_number'];
    });
    this.eService.getBillData(this.slug,this.billNumber).subscribe((data:any)=>{
      this.billJson = data;
      let date = this.billJson.visit.date.split('/');
      if(new Date(date[2]+'-'+date[1]+'-'+date[0]) > new Date('2021-12-31')){
        this.billJson['show_text_invoice'] = true;
      } else {
        this.billJson['show_text_invoice'] = false;
      }
      this.feedback = data.feedback_settings ? data.feedback_settings : data.feedback_response;

      if(this.feedback){// check if feedback is active for merchant
        if(this.feedback.csat[0].rating){// check if feedback is already filled by user
          if(this.feedback.csat.length === 1 && !this.feedback.custom.length && !this.feedback.feedback_comment){
            this.selectedScore = this.feedback.csat[0].rating;
            this.feedbackStatus = 'Feedack received!';
            this.showFeedback = true;
            this.showButton = false;
          } else {
            this.showFeedback = false;
            this.showButton = false;
          }
        }else {
          if(this.feedback.is_feedback_comments){// check if option to fill comments in feedback is present or not
            this.showFeedback = false;
            this.showButton = true;
          }else{
            if(this.feedback.csat.length === 1 && !this.feedback.custom.length){// check if only one question is present or not for csat
              this.showFeedback = true;
              this.showButton = false;
            } else {
              this.showFeedback = false;
              this.showButton = true;
            }
          }
        }
      }else{
        this.showFeedback = false;
        this.showButton = false;
      }

      if(this.billJson){
        if(this.billJson.visit){
          if(this.billJson.visit.items && this.billJson.visit.items.length){
            let sum = 0;
            this.billJson.visit.items.forEach((n:any) => {
              sum += n.item_cost;
            });
            this.subTotal = sum;
          }
          //check for order completition status
          if(this.billJson.visit.source === 'Thrive'
            &&(this.billJson.visit.order_status==='Delivered'
          || this.billJson.visit.order_status==='Completed'
          || (this.billJson.visit.order_status==='Picked Up'&& this.billJson.visit.order_type==='take_away')
          || (this.billJson.visit.order_status==='Picked Up'&& this.billJson.visit.order_type==='dine_in'))){
            this.is_order_completed=true;
          }else if(this.billJson.visit.source !== 'Thrive'){
            this.is_order_completed=true;
          }
        }
      }
    },_error => {
      this.router.navigate(['merchant/details', this.slug]);
    });
  }

  onSelect(i) {
    this.feedback.csat[0]['rating'] = i;
    this.selectedScore = i;
  }

  submitFeedback(){
    this.feedbackStatus = 'Processing....';
    this.feedback['transaction_details']={'bill_amount':'','bill_number':this.billNumber};
    this.feedback['auth_token'] = this.billJson.user.auth_token;
    this.feedback['id'] = this.slug;
    this.feedbackService.submitFeedbackAnswer(this.feedback).subscribe((data:any)=>{
      this.feedbackStatus = 'Feedack received!';
    });
  }

  showImage(i:any){
    if (this.selectedScore !== null && i <= this.selectedScore) {
      return this.feedback.icon_type.selected;
    } else {
      return this.feedback.icon_type.default;
    }
  }

  //function to track order
  trackOrder(){
    window.open("https://thrivenow.in/"+this.billJson.merchant.slug+"/track-order/"+this.billJson.visit.order_id, "_blank");
  }

  //function to navigate to feedback page
  rateUs(){
    this.router.navigate(['web/feedback/'+this.billJson.merchant.slug+'/'+this.billJson.user.auth_token+'/'+this.billJson.visit.bill_number]);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(PopupTaxesComponent, {
      height: "auto",
      data: this.billJson.visit.tax_breakdown
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  
  /**
     * Downloads a tax invoice file.
     * 
     * @description This function is used to download a tax invoice file from the provided URL
     * and authorize the request using the user's authentication token.
     * 
     * @returns void
     */
    downloadTaxInvoice() {
      this.eService.getInvoice(this.slug,this.billNumber).subscribe((result: Blob)=>{
        const url = window.URL.createObjectURL(result);
        // Create a temporary anchor element for downloading the file.
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = this.billJson.visit.bill_number+'_invoice.pdf'; // Specify the file name.
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
    }
}
