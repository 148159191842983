import {
  ElementRef,
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LoginService } from "../login/login.service";
import PinchZoom from "pinch-zoom-js";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
@Component({
  selector: "app-image-popup",
  templateUrl: "./image-popup.component.html",
  styleUrls: ["./image-popup.component.css"]
})
export class ImagePopupComponent implements OnInit {
  index: any;
  nextButton: any;
  prevButton: any;
  constructor(
    public dialogRef: MatDialogRef<ImagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  @Output() leftArrowClicked = new EventEmitter<any>();
  @Output() rightArrowClicked = new EventEmitter<any>();
  @ViewChild("img") img: ElementRef;
  swiperTimeout: any;
  currentZoom: any = 1;
  zoomFactor: any = 0.5;

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: "horizontal",
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    centeredSlides: true
  };
  ngOnInit() {
    if (typeof this.data == "object") {
      this.index = this.data.currentIndex;
    }
  }

  ngAfterViewInit() {
    if (this.detectMob()) {
      document
        .querySelector(".swiper-button-next")
        .addEventListener("click", this.navigationButtonClick);
      document
        .querySelector(".swiper-button-prev")
        .addEventListener("click", this.navigationButtonClick);
    }
  }
  navigationButtonClick() {
    (document.querySelector(".swiper-button-next") as any).style.display =
      "block";
    (document.querySelector(".swiper-button-prev") as any).style.display =
      "block";
  }

  detectMob() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }

  onImageChange(index: number) {
    this.index = index;
  }

  onLeftArrowClicked(type) {
    this.currentZoom = 1;
    this.img.nativeElement.style.transform = `scale(${this.currentZoom},${this.currentZoom})`;
    this.leftArrowClicked.emit(type);
  }

  onRightArrowClicked(type) {
    this.currentZoom = 1;
    this.img.nativeElement.style.transform = `scale(${this.currentZoom},${this.currentZoom})`;
    this.rightArrowClicked.emit(type);
  }

  closePreview() {
    this.dialogRef.close();
  }

  onZoomIn() {
    this.currentZoom += this.zoomFactor;
    if (this.currentZoom > 4) {
      this.currentZoom = 4;
    }
    this.img.nativeElement.style.transform = `scale(${this.currentZoom},${this.currentZoom})`;
  }
  onZoomOut() {
    this.currentZoom -= this.zoomFactor;
    if (this.currentZoom < 1) {
      this.currentZoom = 1;
    }
    this.img.nativeElement.style.transform = `scale(${this.currentZoom})`;
  }

  onSwiperEvent(event: string, actionType: string): void {
    if (event == "transitionStart") {
      clearTimeout(this.swiperTimeout);

      (document.querySelector(".swiper-button-next") as any).style.display =
        "none";
      (document.querySelector(".swiper-button-prev") as any).style.display =
        "none";
    } else if (event == "transitionEnd") {
      this.swiperTimeout = setTimeout(() => {
        (document.querySelector(".swiper-button-next") as any).style.display =
          "block";
        (document.querySelector(".swiper-button-prev") as any).style.display =
          "block";
      }, 1000);
    }
  }
}
