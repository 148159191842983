import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-integrated-solution',
  templateUrl: './integrated-solution.component.html',
  styleUrls: ['./integrated-solution.component.css']
})
export class IntegratedSolutionComponent implements OnInit {
  posList: any;
  @Input() data;
  constructor() { }

  ngOnInit() {
    this.posList = [{imageName: 'hashtag-loyalty-integrated-with-posist',
                    alttitle: 'Integrated with POSist PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with POSist PoS system',
                    class: 'posist'},
                    {imageName: 'hashtag-loyalty-integrated-with-sanguine',
                    alttitle: 'Integrated with Sanguine PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with SanguinePoS system',
                    class: 'sanguine'},
                    {imageName: 'hashtag-loyalty-integrated-with-spectrum',
                    alttitle: 'Integrated with Spectrum PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with Spectrum PoS system',
                    class: 'spectrum'},
                    {imageName: 'hashtag-loyalty-integrated-shawman',
                    alttitle: 'Integrated with Shawman PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with Shawman PoS system',
                    class: 'shawman'},
                    {imageName: 'hashtag-loyalty-integrated-petpooja',
                    alttitle: 'Integrated with PetPooja PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with PetPooja PoS system',
                    class: 'petpooja'},
                    {imageName: 'hashtag-loyalty-integrated-semnox',
                    alttitle: 'Integrated with Semnox PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with Semnox PoS system',
                    class: 'semnox'}];
  }

}
