import { Component, OnInit, ViewChild, HostListener, ElementRef, Inject, Input } from '@angular/core';
import {NavbarserviceService} from './navbarservice.service';
import { Router , NavigationEnd} from '@angular/router';
import {LoginService} from './../login/login.service';
import {MatSidenav} from '@angular/material';
import { WINDOW } from '@ng-toolkit/universal';
import {environment} from '../../../environments/environment';
import { from } from 'rxjs';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  currentUrl: any;
  // scrollpage: any;
  auth_token: any;
  name: any;
  LoginDashboard:string = environment.LOGIN_DASHBOARD_API;
  @Input() scrollTopCount:number;
  @ViewChild('sidenav') public myNav: MatSidenav;
  constructor(@Inject(WINDOW) private window: Window, private router: Router, public nav: NavbarserviceService,
    public loginService: LoginService, private eRef: ElementRef) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
         this.currentUrl = event.url;
      }
    });
   }
  pathArray = ['/reservation', '/loyalty', '/feedback', '/understand', '/engage'];
  pathLoginArray=['/login','/profile','/my-gift-card','/edit/profile'];
  ngOnInit() {
  }
  logout(device) {
    let popup = {logoutmsg: 'Are you done checking out exciting rewards?'};
    this.nav.openDialog(popup);
    if (device === 'mobile') {
      this.myNav.toggle();
    }
  }
  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  handleOutsideClick(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
     this.myNav.close();
    }
  }
}
