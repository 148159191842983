import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  PLATFORM_ID,
  Inject,
  ElementRef
} from "@angular/core";
// import { MatSidenav } from '@angular/material/sidenav';
// import { Router, NavigationEnd } from '@angular/router';
import { MetaServiceService } from "./website/meta-service.service";
import { ScrollDispatcher, CdkScrollable } from "@angular/cdk/scrolling";
import { Router, Event, NavigationEnd } from "@angular/router";
// import { WINDOW } from '@ng-toolkit/universal';
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  // vid: any;
  // landscape: boolean = false;
  // currentUrl: any;
  // playbackConst: any;
  // perDur: any;
  // skip: boolean = false;
  // videoSeen: boolean = false;
  // view: boolean = false;
  // previousUrl: any;
  // isfullscreen: any;
  // @ViewChild('sidenav') sidenav: MatSidenav;
  // @ViewChild('drawerContent') drawerContent: ElementRef;
  scrollingSubscription: any;
  pagechanged: boolean = false;
  windowScrolledCount: number;
  scrollToTopOptions: any = {
    top: 0,
    left: 0,
    behavior: "auto"
  };
  // driftUrlList = [
  //   "/",
  //   "/benefits",
  //   "/loyalty",
  //   "/feedback",
  //   "/reservation",
  //   "/understand",
  //   "/engage",
  //   "/contact-us",
  //   "/about-us",
  //   "/login"
  // ];
  constructor(
    // @Inject(WINDOW) private window: Window,
    // private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    public scroll: ScrollDispatcher,
    private metaService: MetaServiceService,
    private router: Router
  ) {
    this.metaService.updateTitle();
    this.scrollingSubscription = this.scroll
      .scrolled()
      .subscribe((data: CdkScrollable) => {
        this.windowScrolledCount = data.getElementRef().nativeElement.scrollTop;
        if (this.pagechanged && this.windowScrolledCount > 0) {
          this.pagechanged = false;
          data.scrollTo(this.scrollToTopOptions);
        }
        // this.onWindowScroll(data);
      });

    // onWindowScroll(data: CdkScrollable) {
    //   const scrollTop = data.getElementRef().nativeElement.scrollTop || 0;
    // }
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = event.url;
    //     if (this.previousUrl) {
    //       this.videoSeen = true;
    //     }
    //   }
    // });
  }

  // @HostListener('window:scroll', ['$event'])
  //   scrollHandler(event) {
  //     console.debug("Scroll Event");
  // }

  ngOnInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   setTimeout(() => {
    //     (window as any).drift.on("ready", api =>
    //       this.driftHandler.call(this, api)
    //     );
    //   }, 500);
    // }

    // this.playbackConst = 400;
    // this.videoSeen = false;
    // console.log(this.window);
    // screen.orientation.addEventListener('change', () => {
    //   if (screen.orientation.type === 'landscape-primary') {
    //     this.openfullscreen();
    //     this.landscape = true;
    //   } else if (screen.orientation.type === 'portrait-primary') {
    //     this.landscape = false;
    //     this.closefullscreen();
    //   }
    // }, false);
  }

  // driftHandler(api) {
  //   if (!this.driftUrlList.includes(this.router.url)) {
  //     setTimeout(() => {
  //       api.widget.hide();
  //     }, 500);
  //   }

  //   this.router.events.subscribe((event: Event) => {
  //     if (event instanceof NavigationEnd) {
  //       // Hide loading indicator
  //       if (this.driftUrlList.includes(event.url)) {
  //         api.widget.show();
  //       } else {
  //         api.widget.hide();
  //       }
  //     }
  //   });
  // }

  // openfullscreen() {
  //   // Trigger fullscreen
  //   const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
  //     mozRequestFullScreen(): Promise<void>;
  //     webkitRequestFullscreen(): Promise<void>;
  //     msRequestFullscreen(): Promise<void>;
  //   };
  //   if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
  //     docElmWithBrowsersFullScreenFunctions.requestFullscreen();
  //   } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
  //     docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
  //   } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
  //     docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
  //   } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
  //     docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
  //   }
  //   this.isfullscreen = true;
  // }
  // closefullscreen() {
  //   const docWithBrowsersExitFunctions = document as Document & {
  //     mozCancelFullScreen(): Promise<void>;
  //     webkitExitFullscreen(): Promise<void>;
  //     msExitFullscreen(): Promise<void>;
  //   };
  //   if (docWithBrowsersExitFunctions.exitFullscreen) {
  //     docWithBrowsersExitFunctions.exitFullscreen();
  //   } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
  //     docWithBrowsersExitFunctions.mozCancelFullScreen();
  //   } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
  //     docWithBrowsersExitFunctions.webkitExitFullscreen();
  //   } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
  //     docWithBrowsersExitFunctions.msExitFullscreen();
  //   }
  //   this.isfullscreen = false;
  // }
  // videoLength(e) {
  //   let h = Math.floor(e.srcElement.duration) * this.playbackConst + 'px';
  //   document.getElementById('set-height').style.height = h;
  //   document.getElementById('appHeader').style.position = 'absolute';
  // }

  // scroll = (event: any): void => {
  //   this.vid = document.getElementById('v0');
  //   if (this.vid) {
  //     this.vid.currentTime = event.target.scrollTop / this.playbackConst;
  //     this.perDur = ((this.vid.currentTime / this.vid.duration) * 100);
  //     if (this.perDur.toFixed(2) >= 98.64) {
  //       this.vid.classList.add('remove-video');
  //       this.htmlElement();
  //     }
  //   }
  // }

  // skipVideo() {
  //   this.skip = true;
  //   setTimeout(() => {
  //     this.view = true;
  //     this.htmlElement();
  //   }, 1500);
  // }

  // htmlElement() {
  //   this.videoSeen = true;
  //   document.getElementById('appHeader').style.position = 'fixed';
  //   document.getElementById('set-height').classList.add('hide');
  //   document.getElementById('routerContainer').scrollIntoView();
  // }

  onActivate(e) {
    this.pagechanged = true;
  }
}
