import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../field.interface";
@Component({
  selector: 'app-communication',
  templateUrl: './communication.html',
  styleUrls: ['./communication.css']
})
export class communication implements OnInit {

  constructor() { }
  field: FieldConfig;
  group: FormGroup;
  ngOnInit() {
  }

}
