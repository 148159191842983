import { Injectable, Inject } from '@angular/core';
import { LoginService } from '../login/login.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  
  private url: string = environment.WEB_API;
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any, public http: HttpClient,private loginService:LoginService) { }
 
  getUserByAuth(): Observable<any> {
    const auth_token = this.loginService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': auth_token
    });
    return this.http.get(this.url + '/web/api/v2/user/check-user', { headers });
  }
  getUserInfo(): Observable<any>{
    const auth_token = this.loginService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': auth_token
    });
    return this.http.get(this.url + '/web/api/v2/user/user-info', { headers });
  }
  getGiftCard(): Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.loginService.getToken()
    });
    return this.http.get(this.url + '/web/api/v2/user/gift-card-info', {headers});
  }
}
