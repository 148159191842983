import {Component,EventEmitter,Input,OnChanges,OnInit,Output} from "@angular/core";
import { FormGroup,FormBuilder,Validators} from "@angular/forms";
import { FieldConfig, Validator } from "../../input-components/field.interface";
@Component({
  exportAs: "dynamicForm",
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: FieldConfig[] = [];

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  get value() {
    return this.form.value;
  }
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.createControl();
  }
  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      switch(field.type){
        case 'button':
          break;
        case'group':
          const g = this.fb.group({});
          field.controls.forEach((f)=>{
            const c = this.fb.control(
              f.value
            );
            g.addControl(f.name,c);
          });
          group.addControl(field.name,g);
          break;
        default:
          const control = this.fb.control(
            field.value,
            this.bindValidations(field.validations || [])
          );
          group.addControl(field.name, control);
      }
    });
    return group;
  }
  
  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        switch (valid.name) {
          case 'required':
            valid.validator = Validators.required;
          break;
          default:
            let a = valid.validator.trim();
            let patterns = a.substring(2, a.length-2); 
            valid.validator = Validators.pattern(patterns); 
          break;
        }
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
