import { Injectable, Inject } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatDialog, MatDialogRef , MAT_DIALOG_DATA} from '@angular/material';
import {ThankYouPopupComponent} from '../thank-you-popup/thank-you-popup.component';
import { calcBindingFlags } from '@angular/core/src/view/util';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';

@Injectable({
  providedIn: 'root'
})

export class NavbarserviceService {
  visible: boolean;
  dialogRef: any;
  userinfovar: any;
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,  private snackBar: MatSnackBar, public dialog: MatDialog ) {this.visible = true; }
  hide() { this.visible = false; }
  show(){this.visible = true; }
  popup(message, action, classname) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [classname]
    });
  }
  openDialog(data): void {
    this.dialogRef = this.dialog.open(ThankYouPopupComponent, {
     height: 'auto',
     width: '448px',
     closeOnNavigation: true,
     data: data
   });
   this.dialogRef.afterClosed().subscribe(result => {
   });
 }
 userinfo() {
 return this.userinfovar =  JSON.parse(this.localStorage.getItem('user_info'));
 }
}
