import { Component, OnInit, Inject } from "@angular/core";
import { NavbarserviceService } from "../navbar/navbarservice.service";
import { MatDialog } from "@angular/material";

import { ReferMerchantPopupComponent } from "./refer-merchant-popup/refer-merchant-popup.component";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { ProfileService } from "../user-details/profile.service";
import { ReferralService } from "./referral-service.service";
import { LoginService } from "../login/login.service";
@Component({
  selector: "app-refer-merchant",
  templateUrl: "./refer-merchant.component.html",
  styleUrls: ["./refer-merchant.component.css"]
})
export class ReferMerchantComponent implements OnInit {
  slug: any;
  smsContent: string;
  emailContent: string;
  emailSubject: string;
  whatsappContent: string;
  messengerContent: string;
  auth: any;
  header: any;
  referBtnDisabled: boolean;
  mname: any;
  referral_url: any;
  rname: any;
  constructor(
    private navbarService: NavbarserviceService,
    private loginService: LoginService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private nav: NavbarserviceService,
    private profileService: ProfileService,
    private referralService: ReferralService,
    @Inject(LOCAL_STORAGE) private localStorage: any
  ) {}

  ngOnInit() {
    this.navbarService.hide();
    this.referBtnDisabled = true;
    //get route params
    this.route.params.forEach(urlParams => {
      this.slug = urlParams["slug"];
      this.auth = urlParams["auth"];
    });
    this.header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.auth
    });
    this.localStorage.setItem("auth_token", this.auth);

    //Login user with the auth token
    this.profileService.getUserByAuth().subscribe((data: any) => {
        this.localStorage.setItem("user_name", data.user.fname);
        this.localStorage.setItem("user_details", JSON.stringify(data.user));
        this.checkForReferralValidity();
      },
      _error => {
        this.localStorage.clear();
      }
    );
    let params = { id: this.slug };
    this.referralService.getReferralData(params).subscribe((data:any) => {
      this.referral_url = data.user_link;
    });
  }
  detectmob() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    return false;
  }
  //check for the activation status of the referral
  checkForReferralValidity() {
    let params = { id: this.slug };
    const auth_token = this.loginService.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: auth_token
    });
    this.referralService.getReferralValidity(params, headers).subscribe(data => {
        if (data.data && data.data.activated) {
          this.mname = data.data.mname;
          this.rname = data.data.rname;
          if(this.referral_url){
            this.referBtnDisabled = false;
          }
        } else {
          this.router.navigateByUrl(`/merchant/details/${this.slug}`).then(() => {
              let popup = {
                type: "Hey! This offer doesn't exist anymore.",
                image: "error",
                fromReferral: true
              };
              this.nav.openDialog(popup);
          });
        }
      });
  }

  //method to get a data related to the referral program
  getReferralData() {
      let modalData: any = {};
      modalData.smsContent = `I have a referral reward for you at ${this.mname}. Get ${this.rname} on your visit. Claim your reward: ${this.referral_url}`;
      modalData.emailSubject = `I have a referral reward for you at ${this.mname}!`;
      modalData.emailContent = `Hey, ${this.detectmob()?'<br/><br/>':'%0D%0A%0D%0A'}I have a referral reward for you at ${this.mname}. Get ${this.rname} on your visit. Claim your reward: ${this.referral_url}`;
      modalData.whatsappContent = `I have a referral reward for you at ${this.mname}. Get ${this.rname} on your visit. Claim your reward: ${this.referral_url}`;
      modalData.messengerContent = `I have a referral reward for you at ${this.mname}. Get ${this.rname} on your visit. Claim your reward: https://${this.referral_url}`;
      modalData.link = this.referral_url;
      modalData.slug = this.slug;
      const dialogRef = this.dialog.open(ReferMerchantPopupComponent, {
        height: "auto",
        width: "500px",
        data: modalData
      });
      dialogRef.afterClosed().subscribe(result => {});
  }
}
