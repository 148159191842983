import {Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {EdituserprofileService} from '../edituserprofile/edituserprofile.service';
import {LoginService} from '../login/login.service';
import {Router} from '@angular/router';
import { timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import {NavbarserviceService} from '../navbar/navbarservice.service';
declare var hj;
@Component({
  selector: 'app-dialogpopup',
  templateUrl: './dialogpopup.component.html',
  styleUrls: ['./dialogpopup.component.css']
})
export class DialogpopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogpopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private editProfileService: EdituserprofileService,
              private navService: NavbarserviceService,
              private router: Router,
              private loginService: LoginService) { }
  email_mobile: any;
  mergeaccountnumber: any;
  mergeAccountOtp: any;
  otpViewEnable: any;
  countDown: any;
  counter = 120;
  otp: any;
  is_email_mobile_invalid: any;
  is_invalid_otp: any;

  link: any;
  ngOnInit() {
    this.link = 'http ansadbsajdjsa';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  changeEmailMobile() {
    this.is_email_mobile_invalid = undefined;
  }
  changeOtp() {
    this.is_invalid_otp = undefined;
  }
  submit() {
    let emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    let mobRegex = /^\d{10}$/;
    if (this.email_mobile) {
      if (emailRegex.test(this.email_mobile) || mobRegex.test(this.email_mobile)) {
        if (this.loginService.getUserInfo().mobile !== this.email_mobile){
          this.otpViewEnable = true;
          this.resentotpFun();
        } else {
          this.is_email_mobile_invalid = 'Enter different mobile number';
        }
      } else {
        this.is_email_mobile_invalid = 'In-valid Mobile Number Or Email-Id';
      }
    } else {
      this.is_email_mobile_invalid = 'Enter Mobile Number Or Email-Id';
    }
  }

  submitotp() {
    let otpRegex = /^\d{4}$/;
    if (otpRegex.test(this.otp)) {
     this.mergeAccountOtp = {login_par: this.email_mobile, auth_token: this.loginService.getToken(), otp: this.otp};
      this.editProfileService.mergeAccountverifyOtp(this.mergeAccountOtp).subscribe((date: any) => {
        // hotjar virtual page view code
        hj('vpv', '/edit/profile/merge/sent-otp/success');
        let popup = {type: 'Awesome! You\'ve merged your accounts successfully!', image: 'thankyou'};
        this.navService.openDialog(popup);
        setTimeout(() => {
          this.router.navigate(['/profile']);
        }, 2500);
        }, error => {
          let popup = {type: 'Oops! Something went wrong, please try again after some time.', image: 'error'};
          this.navService.openDialog(popup);
      });
    this.onNoClick();
    } else {
      this.is_invalid_otp = 'Enter valid OTP';
    }
  }

  resentotpFun() {
    this.countDown = undefined;
    this.counter = 120;
    this.counterFun();
    this.otp = undefined;
    this.otpSendFun();
  }

  otpSendFun() {
    this.mergeaccountnumber = {login_par: this.email_mobile, auth_token: this.loginService.getToken()};
      this.editProfileService.mergeAccount(this.mergeaccountnumber).subscribe((data: any) => {
        // hotjar virtual page view code
        hj('vpv', '/edit/profile/merge/sent-otp');
        this.navService.popup('OTP sent successfully', '', 'popup-success');
        }, error => {
          this.onNoClick();
          let popup = {type: 'Oops! User not found.', image: 'error'};
          this.navService.openDialog(popup);
      });
  }

  counterFun() {
    this.countDown = timer(0, 1000).pipe(
      take(this.counter),
      map(() => --this.counter));
  }
  copyClipboard(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    let tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = 'clipboard Copied';
  }
  
}
