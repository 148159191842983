import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA ,MatDialog, MatDialogRef,} from "@angular/material";
import { ReferralService } from "../referral-service.service";

@Component({
  selector: "app-refer-merchant-popup",
  templateUrl: "./refer-merchant-popup.component.html",
  styleUrls: ["./refer-merchant-popup.component.css"]
})
export class ReferMerchantPopupComponent implements OnInit {
  link: string = "www.google.com";
  copied: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialModRef:MatDialogRef<any>,
    private referralService: ReferralService,
  ) {}

  ngOnInit() {
  }
  //detect mobile platform
  detectmob() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    return false;
  }

  //detect android mobile
  detectAndroidMob() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    return false;
  }

  //detect IOS mobile
  detectIOSMob() {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      return true;
    }
    return false;
  }

  //method to copy link into clipboard
  copyToClipboard() {
    if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      var textarea = document.createElement("textarea");
      textarea.textContent = this.data.link;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        this.copied = true;

        setTimeout(() => {
          this.copied = false;
        }, 5000);
        document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
      } finally {
        document.body.removeChild(textarea);
        this.changeSharedPlatformCount("link");
      }
    }
  }

  //method to call API to increment the number of clicks on a particular platform
  changeSharedPlatformCount(medium: string) {
    let params = { id: this.data.slug, medium: medium };
    this.referralService.changeSharedPlatformCount(params).subscribe(() => {});
  }
}
