import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-specks',
  templateUrl: './customer-specks.component.html',
  styleUrls: ['./customer-specks.component.css']
})
export class CustomerSpecksComponent implements OnInit {
  activeCarousel: any;
  // engageFeatures: any;
  constructor() { }
  slides = [
    { logo: 'massive', imagename: 'massive-restaurants', content: 'We’ve taken the customer experience at Massive Hospitality a notch higher by launching an exclusive member’s program for our loyal customers. Our custom requirements have been deployed at scale across all our brands by Hashtag Loyalty.', brand: 'Massive Restaurants', name: 'Amrit Grewal', designation: 'Head of Marketing', alt: 'Logo of Hashtag Loyalty partner business Massive Restaurants', title: 'Solution for Massive Restaurants' },
    { logo: 'bwc', imagename: 'the-belgian-waffle-co', content: 'We track real-time business performance, collect and interpret customer data from 250+ franchise outlets panning across India and easily execute brand level campaigns, all through one platform - Hashtag Loyalty.', brand: 'The Belgian Waffle Co.', name: 'Shrey Aggarwal', designation: 'Founder', alt: 'Logo of Hashtag Loyaly partner business The Belgian Waffle Co.', title: 'Solution for The Belgian Waffle Co.' },
    { logo: 'jumboking', imagename: 'jumboking', content: 'We had clear objectives - promote specific units and increase visits - to fulfil via the Jumboking loyalty program. Hashtag Loyalty, with a customized and PoS integrated program, helped us achieve exactly that.', brand: 'Jumboking', name: 'Dheeraj Gupta', designation: 'Founder', alt: 'Logo of Hashtag Loyalty partner business Jumboking', title: 'Solution for Jumboking' },
    { logo: 'apsara', imagename: 'apsara-ice-creams', content: 'Apsara Ice Creams has consistently stayed ahead of the competition by ensuring regular customer engagement. We’ve also drastically improved our franchise performance by introducing an owner incentive program based on the Monthly Leaderboard by Hashtag Loyalty.', brand: 'Apsara Ice Creams', name: 'Kiran Shah', designation: 'Founder', alt: 'Logo of Hashtag Loyalty partner business Apasra Ice Creams', title: 'Solution for Apsara Ice Creams' },
    { logo: 'smash', imagename: 'smaaash-entertainment', content: 'With multiple PoS system integrations, data reconciliation requests and tight timelines involved, the formidable task of implementing a loyalty program across all Smaaash locations was executed seamlessly by Hashtag Loyalty.', brand: 'Smash Entertainment', name: 'Abhishek Agarwal', designation: 'Head of Marketing', alt: 'Logo of Hashtag Loyalty partner business Smaaash Entertainment ', title: 'Solution for Smaaash Entertainment' }
  ];
  slideConfig = {
    'slidesToShow': 3,
    'slidesToScroll': 3,
    // "dots": true,
    'infinite': true,
    'autoplay': true,
    // "mobileFirst": true,
    // "accessibility": true,
    'centerMode': true,
    'centerPadding': '10px',
    // "adaptiveHeight": true,
    'autoplaySpeed': 3000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      },
      {
        breakpoint: 320,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }]
  };
  afterChange(e) {
    this.activeCarousel = e.currentSlide;
  }

  ngOnInit() {
  }

}
