import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-generic-campaign-report-row',
  templateUrl: './generic-campaign-report-row.component.html',
  styleUrls: ['./generic-campaign-report-row.component.css']
})
export class GenericCampaignReportRowComponent implements OnInit {
  @Input() campaignMetrics: any;
  constructor() { }

  ngOnInit() {
  }

}
