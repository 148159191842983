import { Component, OnInit, Inject,PLATFORM_ID } from '@angular/core';
import { NavbarserviceService } from '../navbar/navbarservice.service';
import { FeedbackService } from '../web-feedback/feedback.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../user-details/profile.service';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { MatDialog } from "@angular/material";
import * as _ from 'lodash';
import { EdituserprofileService} from '../edituserprofile/edituserprofile.service';
import { EdituserprofileComponent } from "../edituserprofile/edituserprofile.component";
import { isPlatformBrowser } from "@angular/common";
@Component({
  selector: 'app-web-feedback',
  templateUrl: './web-feedback.component.html',
  styleUrls: ['./web-feedback.component.css']
})
export class WebFeedbackComponent implements OnInit {
  selectedStar: any;
  selectedRate: any;
  selcetdnps: any;
  avg: any;
  auth: any;
  id: any;
  billnumber:any;
  comment: any;
  bgimage: any;
  question: any;
  iconName: any;
  submitted: any;
  is_submitted: any;
  dialogRef: any;
  testBrowser: any;
  feedbackResponse:any;
  profileForm:any;
  npsSelected:boolean = false;
  queryParams:any={};
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any, 
              public nav: NavbarserviceService, 
              private feedbackService: FeedbackService,
              private route: ActivatedRoute, 
              private router: Router, 
              private profileService: ProfileService,
              public dialog: MatDialog,
              private editProfileService:EdituserprofileService,
              @Inject(PLATFORM_ID) platformId: string) { 
                this.testBrowser = isPlatformBrowser(platformId);
              }
  ngOnInit() {
    this.nav.hide();
    this.is_submitted = false;
    this.submitted = false;
    this.route.params.forEach((urlParams) => {
      this.auth = urlParams['auth'];
      this.id = urlParams['id'];
      this.billnumber = urlParams['billnumber'];
    });

    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    })

    if (this.auth) {
      this.localStorage.setItem('auth_token', this.auth);
    }
    const params = { auth_token:this.auth, id:this.id, bill_number:this.billnumber};
    this.feedbackService.getFeedbackQuestion(params).subscribe((data: any) => {
      this.question = data;
      this.iconName = this.question.feedback_settings.icon_type.default;
      this.bgimage = this.question.feedback_settings.background_image_url ?
        this.question.feedback_settings.background_image_url : '../../../assets/generic-bg-image.png';
      this.selectedStar = Array(this.question.feedback_settings.csat.length).fill(null);
      this.selectedRate = Array(this.question.feedback_settings.csat.length).fill(null);
    }, _error => {
      let popup = { type: 'Hey! Thanks, we\'ve already received your Feedback.', image: 'info' };
      this.nav.openDialog(popup);
      setTimeout(() => {
        this.nav.dialogRef.close();
        this.router.navigate(['/merchant/details/' + this.id]);
      }, 2500);
    });
    this.profileService.getUserInfo().subscribe((data: any) => {
        this.localStorage.setItem("user_name", data.user.fname);
        this.localStorage.setItem("user_details", JSON.stringify(data.user));
      },_error => {
        this.localStorage.clear();
    });
  }
  onSelect(i:any, q_index:any) {
    this.selectedStar[q_index] = i + 1;
    this.selectedRate[q_index] = this.selectedStar[q_index];
    let count = 0;
    for (let i = 0; i < this.selectedRate.length; i++) {
      if (this.selectedRate[i]) {
        count++;
      }
    }
    if (count === this.selectedRate.length) {
      this.avg = _.sum(this.selectedRate) / this.selectedRate.length;
    }
  }
  onSelecthover(i, q_index) {
    this.selectedStar[q_index] = i + 1;

  }
  onMouseleave(q_index, _i) {
    this.selectedStar[q_index] = this.selectedRate[q_index];

  }
  onNpsselect(i) {
    this.selcetdnps = i;
    this.npsSelected = true;
  }
  showImage(i, indexin) {
    if (this.selectedStar[indexin] - 1 !== null && i <= this.selectedStar[indexin] - 1) {
      return this.iconName = this.question.feedback_settings.icon_type.selected;
    } else {
      return this.iconName = this.question.feedback_settings.icon_type.default;
    }
  }
  selectedMCQOption(custom, option) {
    custom['response'] = option;
  }
  submitFeedback(data) {
    this.feedbackResponse = data;
    Object.assign(this.feedbackResponse , this.queryParams);
    this.is_submitted = true;
    let responseCsat = 0;
    let responsecCustom = 0;

    for (let i = 0; i < this.feedbackResponse.csat.length; i++) {
      if (this.selectedRate[i]) {
        responseCsat++;
      }
      this.feedbackResponse.csat[i]['rating'] = this.selectedRate[i];
    }

    for (let i = 0; i < this.feedbackResponse.custom.length; i++) {
      if (this.feedbackResponse.custom[i].response) {
        responsecCustom++;
      }
    }

    if(this.feedbackResponse.nps.length){
      this.feedbackResponse.nps[0]['response'] = this.selcetdnps;
      if(this.selcetdnps !== undefined){
        this.npsSelected = true;
      } else{
        this.npsSelected = false;
      } 
    } else {
      this.npsSelected = true;
    }
    this.submitted = true;
    this.feedbackResponse['auth_token'] = this.auth;
    this.feedbackResponse['id'] = this.id;
    if(this.billnumber){
      this.feedbackResponse['transaction_details']={'bill_amount':'','bill_number':this.billnumber};
    }
    if ((responseCsat !== this.question.feedback_settings.csat.length) ||
      (!this.npsSelected) ||
      (responsecCustom !== this.question.feedback_settings.custom.length) ||
      ((this.avg < 2.5) && !this.feedbackResponse.avg_poor_csat_response)) {
      this.is_submitted = false;
      let popup = { type: 'Please submit a valid response to proceed', image: 'error' };
      this.nav.openDialog(popup);
      setTimeout(() => {
        this.nav.dialogRef.close();
      }, 2500);
    } else {
      if ((this.avg >= 2.5)) {
        this.feedbackResponse['avg_poor_csat_response'] = '';
      }
      if (this.question && this.question.merchant.is_complete_profile) {
        this.editProfileService.getFormKeyById(this.question.merchant.id).subscribe((data:any)=>{
          this.profileForm = Object.assign([],data);
          this.handleCompleteProfile(this.feedbackResponse);
        })
          
      } else {
        this.feedbackService.submitFeedbackAnswer(this.feedbackResponse).subscribe((_submit: any) => {
          let popup = { type: 'Thank you for your valuable Feedback!', image: 'thankyou' };
          this.nav.openDialog(popup);
          setTimeout(() => {
            this.nav.dialogRef.close();
            this.router.navigate(['/merchant/details/' + this.id]);
          }, 2500);
        }, (_error) => {
          this.is_submitted = false;
        });
      }
    }
  }
  handleCompleteProfile(feedbackResponse:any) {
    if (this.testBrowser) {
      let data: any = {};
      let config: any = {};
      data.fromfill='feedback';
      data.profileForm = this.profileForm;
      data.editProfile = true;
      data.slug = this.question.merchant.slug;
      data.merchantName = this.question.merchant.name;
      config.height = "auto";
      config.minWidth = "60vw";
      config.disableClose = true;
      config.closeOnNavigation = true;
      config.data = data;
      config.maxWidth = "90vw";
      config.panelClass = 'edit-profile-dialog-container';
      this.dialogRef = this.dialog.open(EdituserprofileComponent, config);
      this.dialogRef.afterClosed().subscribe(result => {
        this.feedbackService.submitFeedbackAnswer(feedbackResponse).subscribe((_submit: any) => {
          let popup = { type: 'Thank you for your valuable Feedback!', image: 'thankyou' };
          this.nav.openDialog(popup);
          setTimeout(() => {
            this.nav.dialogRef.close();
            this.router.navigate(['/merchant/details/' + this.id]);
          }, 2500);
        }, (_error) => {
          this.is_submitted = false;
        });
      });
    }
  }
}
