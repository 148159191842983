import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { WINDOW } from "@ng-toolkit/universal";
@Component({
  selector: "app-url-shortner",
  templateUrl: "./url-shortner.component.html",
  styleUrls: ["./url-shortner.component.css"]
})
export class UrlShortnerComponent implements OnInit {
  private url: string = environment.WEB_API;
  constructor(
    @Inject(WINDOW) private window: Window,
    public http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  id: any;
  headertype = new HttpHeaders({
    "Content-Type": "application/json"
  });

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.http
      .get(this.url + "/web/api/v2/get_shorturl_data", {
        headers: this.headertype,
        params: { id: this.id }
      })
      .subscribe(
        (data: any) => {
          if (data.redirect_url.includes("gamification")) {
            this.window.location.href =
              "https://spinthewheel.hashtagloyalty.com/" + data.redirect_url;
          } else if (data.redirect_url.includes("https://")) {
            this.window.location.href = data.redirect_url;
          } else {
            this.router.navigateByUrl("/"+data.redirect_url);
          }
        },
        error => {
          this.router.navigate(["/"]);
        }
      );
  }
}
