import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder,FormGroup, Validators} from "@angular/forms";
import { timer } from "rxjs";
import * as moment from "moment";
import { take, map } from "rxjs/operators";
import { LoginService } from "../../../login/login.service";
import { NavbarserviceService } from "../../../navbar/navbarservice.service";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { EdituserprofileService } from "../../../edituserprofile/edituserprofile.service";
import { ReferralService } from "../../referral-service.service";

@Component({
  selector: "app-claim-reward-popup",
  templateUrl: "./claim-reward-popup.component.html",
  styleUrls: ["./claim-reward-popup.component.css"]
})
export class ClaimRewardPopupComponent implements OnInit {
  submittedOTP: boolean = false;
  mobileVerified: boolean = false;
  otpVerfied: boolean = false;
  countDown: any;
  counter: number = 30;
  is_disabled: boolean = false;
  is_disabledInfo: boolean = false;
  verifyMobileNumber: FormGroup;
  submitInfo: FormGroup;
  submittedmobile = false;
  submitted = false;
  infoSubmitted = false;
  maxDate = new Date();
  successfulClaim: boolean = false;
  rejectedClaim: boolean = false;
  locationAllowed = false;
  skip: boolean;
  password: string;
  is_invalid: boolean = false;
  mobile_invalid: boolean = false;
  updateddata: any;
  errMsg: any;
  mname: any;
  expiryDate: any;
  url:any;
  user:any;
  constructor(
    public dialogRef: MatDialogRef<ClaimRewardPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private navbarService: NavbarserviceService,
    private editService: EdituserprofileService,
    private refrralService: ReferralService,
    @Inject(LOCAL_STORAGE) private localStorage: any
  ) {}

  ngOnInit() {
    this.verifyMobileNumber = this.formBuilder.group({
      mobile: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^[0-9]*$"),
          Validators.maxLength(10)
        ]
      ],
      otp: [
        "",
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ]
    });
    this.submitInfo = this.formBuilder.group({
      mobile: [
        { value: "", disabled: true },
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern("^[0-9]*$"),
          Validators.maxLength(10)
        ]
      ],
      name: [""],
      email: ["", Validators.email],
      date_of_birth: null
    });
  }
  get f() {
    return this.verifyMobileNumber.controls;
  }

  //method to verify validity of mobile number
  verifyMobile(event) {
    event.preventDefault();
    // this.is_disabled = true;
    // this.submittedmobile = true;

    if (this.verifyMobileNumber.controls.mobile.valid) {
      this.resentotpFun();
      this.is_disabled = true;
    }
  }

  //method to send otp
  sendOtp() {
    this.loginService
      .getOTPUser(this.verifyMobileNumber.controls.mobile.value)
      .subscribe(
        (message: any) => {
          this.mobileVerified = true;
          this.mobile_invalid = false;
          this.is_disabled = false;
        },
        err => {
          if (err.status === 401) {
            this.mobile_invalid = true;
          } else {
            let popup = {
              type:
                "Oops! Something went wrong, please try again after some time.",
              image: "error"
            };
            this.navbarService.openDialog(popup);
          }
          this.is_disabled = false;
        }
      );
  }
  verifyOTP(event) {
    event.preventDefault();
    // this.submittedOTP = true;
    // this.is_disabled = true;
    if (this.verifyMobileNumber.controls.otp.valid) {
      this.is_disabled = true;
      const credential = {
        login: this.verifyMobileNumber.controls.mobile.value,
        password: btoa(this.password),
        otp_status: "true",
        otp: this.verifyMobileNumber.controls.otp.value.toString()
      };
      this.loginService.loginUser(credential).subscribe(
        (data: any) => {
          // hj('vpv', '/login/otp/login-success');
          this.otpVerfied = true;
          this.getUserData();
        },
        error => {
          this.submittedOTP = false;
          this.is_disabled = false;
          if (error.status === 401) {
            this.is_invalid = true;
          } else {
            let popup = {
              type:
                "Oops! Something went wrong, please try again after some time.",
              image: "error"
            };
            this.navbarService.openDialog(popup);
          }
        }
      );
    }
  }

  get fSubmit() {
    return this.submitInfo.controls;
  }

  //method to resend otp
  resentotpFun() {
    this.countDown = undefined;
    this.counter = 30;
    this.counterFun();
    this.sendOtp();
    this.verifyMobileNumber.controls.otp.reset();
    this.submittedOTP = false;
  }
  //method for counting time
  counterFun() {
    this.countDown = timer(0, 1000).pipe(
      take(this.counter),
      map(() => --this.counter)
    );
  }

  //skip the edit profile page popup
  skipEditProfile() {
    this.skip = true;
    this.claimReward();
  }
  //get user data and patch value in the edit form
  getUserData() {
    this.user = JSON.parse(this.localStorage.getItem("user_details"));
    let lname = this.user.lname ? this.user.lname : '';
    let fname = this.user.fname ? this.user.fname : '';
    let name = fname+' '+lname; 
    this.submitInfo.patchValue({
      name: name,
      email: this.user.email,
      mobile: this.user.mobile
    });
    if (this.user.date_of_birth && this.user.date_of_birth.length) {
      let dob: any = moment(this.user.date_of_birth).format("YYYY/MM/DD");
      dob = dob.split("/");
      dob[0] = Math.abs(parseInt(dob[0]));
      dob = dob.join("/");
      this.submitInfo.patchValue({
        date_of_birth: new Date(dob)
      });
    }
    
  }

  //submit the edit form
  onSubmitInfo(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.submitInfo.valid) {
      this.is_disabledInfo = true;
      let dob: any = this.submitInfo.value.date_of_birth
        ? moment(this.submitInfo.value.date_of_birth).format("YYYY/MM/DD")
        : "";
      if (dob && dob.length) {
        dob = dob.split("/");
        dob[0] = Math.abs(parseInt(dob[0]));
        dob = dob.join("/");
      }
      this.updateddata = {
        fname: this.submitInfo.value.name.split(" ")[0],
        lname: this.submitInfo.value.name.split(" ")[1],
        date_of_birth: dob,
        email: this.submitInfo.value.email,
        mobile: this.submitInfo.value.mobile,
        anniversary: this.user.anniversary,
        gender: this.user.gender,
        email_notification: this.user.is_email,
        sms_notification: this.user.is_sms
      };
      this.editService.editProfileUser(this.updateddata).subscribe(
        (data: any) => {
          this.infoSubmitted = true;
          this.claimReward();
        },
        error => {
          this.is_disabledInfo = false;
        }
      );
    }
  }

  //method to claim reward
  claimReward() {
    let params = {
      id: this.data.slug,
      referrer_token: this.data.referrerToken
    };
    if(this.data.chainid){
      this.url= '/merchant/partner-locations/chain/'+this.data.chainid;
    } else {
      this.url = '/merchant/details/'+this.data.slug;
    }
    this.refrralService.claimReward(params).subscribe(
      (data: any) => {
        this.successfulClaim = true;
        this.mname = data.mname;
        this.expiryDate = data.expiry_date;
      },
      err => {
        this.successfulClaim = false;
        if (err.status == 422) {
          this.errMsg = err.error.message;
        } else {
          this.errMsg = `Unable to claim the reward ${this.data.rname} right now.Please try again later after sometime`;
        }
      }
    );
  }
  // toggleLocationAccess() {
  //   this.locationAllowed = !this.locationAllowed;
  // }
}
