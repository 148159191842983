import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.css']
})
export class LoyaltyComponent implements OnInit {
  features: any;
  salient_features: any;
  data:any;
  constructor() { }
  ngOnInit() {
    this.features = [{cardImage: 'visit-based-loyalty-program',
                    cardTitle: 'Visit Based',
                    alttitle: 'Visit based loyalty program',
                    alttext: 'Logo for Visit based loyalty program',
                    cardContent: 'Reward points to customers for each visit or check-in at your business.'},
                    {cardImage: 'spend-based-loyalty-program',
                    cardTitle: 'Spend Based',
                    alttitle: 'Spend based loyalty program',
                    alttext: 'Logo for Spend based loyalty program',
                    cardContent: 'Reward points to customers proportional to their spends at your business.'},
                    {cardImage: 'tier-based-loyalty-program',
                    cardTitle: 'Tier Based',
                    alttitle: 'Tier based loyalty program',
                    alttext: 'Logo for Tier based loyalty program',
                    cardContent: 'Reward points to customers at a rate based on tiers which are defined by their overall spends at your business.'},
                    {cardImage: 'cashback-loyalty-program',
                    cardTitle: 'Cashback',
                    alttitle: 'Cashback based loyalty program',
                    alttext: 'Logo for Cashback loyalty program',
                    cardContent: 'Reward cashback to customers proportional to their spends.'},
                    {cardImage: 'unit-loyalty-program',
                    cardTitle: 'Unit Based',
                    alttitle: 'Unit loyalty program',
                    alttext: 'Logo for Unit loyalty program',
                    cardContent: 'Reward points to customers for purchasing specific SKUs at your business.'},
                    {cardImage: 'custom-loyalty-program',
                    cardTitle: 'Custom',
                    alttitle: 'Custom loyalty program for your business',
                    alttext: 'Logo for Custom loyalty program for your business',
                    cardContent: 'Alter any of the existing structures and create a program unique to fit the needs of your business.'}];

    this.salient_features = [{cardImage: 'define-points-expiry',
                            cardTitle: 'Points Expiry',
                            alttitle: 'Point expiry to check liability and increase redemption',
                            alttext: 'Icon for point expiry to check liability and increase redemption',
                            cardContent: 'Keep business liability in check and create an urgency to redeem by defining a validity period on the points rewarded to customers.'},
                            {cardImage: 'online-offline-store-omnichannel-integration',
                            cardTitle: 'Online Store Integration',
                            alttitle: 'Integrate online and offline store',
                            alttext: 'Icon for omnichannel business via online and offline store integration',
                            cardContent: 'Make your business truly omni-channel by integrating your online and offline stores. Derive comprehensive customer data and analytics.'},
                            {cardImage: 'bonus-point-rewards',
                            cardTitle: 'Bonus points',
                            alttitle: 'Bonus points reward for promotions',
                            alttext: 'Icon for bonus points rewards for promotion of slow hours, items, campaigns',
                            cardContent: 'Reward bonus points to customers to promote specific SKUs, campaigns or slow hours, as per your requirements.'},
                            {cardImage: 'gamification-experience',
                            cardTitle: 'Gamification',
                            alttitle: 'Enhance customer experience with gamification',
                            alttext: 'Icon for enhance customer experience with gamification',
                            cardContent: 'Enhance customer experience with gamified campaigns, promotions, etc.'},
                            {cardImage: 'customer-loyalty-analytics',
                            cardTitle: 'Loyalty analytics',
                            alttitle: 'Track loyalty program performance with analytics',
                            alttext: 'Icon for track performance of loyalty program with analytics and reporting',
                            cardContent: 'Keep track of the performance and get actionable insights on your program with in-depth analytics and reporting.'},
                            {cardImage: 'automated-customer-engagement',
                            cardTitle: 'Automated Engagement',
                            alttitle: 'Automated customer engagement',
                            alttext: 'Icon for communication via automated customer engagement',
                            cardContent: 'Communicate with customers via a channel they’re most likely to respond to.'}];
  this.data = {heading: 'PLUG AND PLAY + POS INTEGRATED',
              content: 'Implement Hashtag Loyalty in a matter of minutes compared to the days if not weeks taken by other solutions. Implement a solution connected to your existing billing system.'};
  }
}
