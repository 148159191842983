import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./website/home/home.component";
import { ReservationComponent } from "./website/reservation/reservation.component";
import { FeedbackComponent } from "./website/feedback/feedback.component";
import { LoyaltyComponent } from "./website/loyalty/loyalty.component";
import { UnderstandComponent } from "./website/understand/understand.component";
import { EngageComponent } from "./website/engage/engage.component";
import { BenefitsComponent } from "./website/benefits/benefits.component";
import { ContactUsComponent } from "./website/contact-us/contact-us.component";
import { HiringComponent } from "./website/hiring/hiring.component";
import { JobDetailComponent } from "./website/job-detail/job-detail.component";
import { LoginComponent } from "./website/login/login.component";
import { MerchantWeeklyReportComponent } from "./website/merchant-weekly-report/merchant-weekly-report.component";
import { MerchantCampaignReportComponent } from "./website/merchant-campaign-report/merchant-campaign-report.component";
import { UserDetailsComponent } from "./website/user-details/user-details.component";
import { WebFeedbackComponent } from "./website/web-feedback/web-feedback.component";
import { AboutUsComponent } from "./website/about-us/about-us.component";
import { MerchantDetailsComponent } from "./website/merchant-details/merchant-details.component";
import { LocationComponent } from "./website/location/location.component";
import { TeamComponent } from "./website/team/team.component";
import { TermsPrivacyComponent } from "./website/terms-privacy/terms-privacy.component";
import { EdituserprofileComponent } from "./website/edituserprofile/edituserprofile.component";
import { UrlShortnerComponent } from "./website/url-shortner/url-shortner.component";
import { SpinTheWheelComponent } from "./website/spin-the-wheel/spin-the-wheel.component";

import { ReferMerchantComponent } from "./website/refer-merchant/refer-merchant.component";
import { ReferMerchantPopupComponent } from "./website/refer-merchant/refer-merchant-popup/refer-merchant-popup.component";
import { ClaimRewardComponent } from "./website/refer-merchant/claim-reward/claim-reward.component";
import { ClaimRewardPopupComponent } from "./website/refer-merchant/claim-reward/claim-reward-popup/claim-reward-popup.component";
import { EBillComponent } from "./website/e-bill/e-bill.component";
import { RewardViewComponent } from './website/merchant-details/reward-view/reward-view.component';
import { GiftCardComponent } from './website/gift-card/gift-card.component';
const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    data: {
      title:
        "Best Customer Loyalty Program in India | Increase Customer Retention",
      description:
        "Capture customer data via a loyalty program, feedback or reservation systems. Understand customer behaviour through reports, segmentation & analytics. Engage customers via data driven, automatic and personalised communication."
    }
  },
  {
    path: "reservation",
    component: ReservationComponent,
    data: {
      title: "Restaurant Reservation System",
      description: "Capture customer data via a customer reservations system."
    }
  },
  {
    path: "feedback",
    component: FeedbackComponent,
    data: {
      title: "Customer Feedback System",
      description:
        "Capture customer data via a tablet or link based customer feedback system."
    }
  },
  {
    path: "loyalty",
    component: LoyaltyComponent,
    data: {
      title: "Loyalty Program Solution",
      description:
        "Capture customer data via a loyalty program and establish long-lasting customer relationships."
    }
  },
  {
    path: "understand",
    component: UnderstandComponent,
    data: {
      title: "Understand Customer Behaviour",
      description:
        "Interpret captured customer data via analytics, reports and real-time performance dashboard."
    }
  },
  // { path: 'hiring', component: HiringComponent},
  {
    path: "engage",
    component: EngageComponent,
    data: {
      title: "Engage Customers",
      description: "Automate customer engagement based on customer activity."
    }
  },
  {
    path: "benefits",
    component: BenefitsComponent,
    data: {
      title: "Benefits of Hashtag Loyalty",
      description:
        "Why businesses trust Hashtag Loyalty to build customer loyalty and increase revenue."
    }
  },
  {
    path: "contact-us",
    component: ContactUsComponent,
    data: {
      title: "Contact Hashtag Loyalty"
    }
  },
  // { path: 'jobdetail/:id', component: JobDetailComponent},
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login in to Hashtag Loyalty"
    }
  },
  {
    path: "report/:auth/:report_date/:period",
    component: MerchantWeeklyReportComponent,
    data: {
      title: "Weekly Performance Report | Hashtag Loyalty"
    }
  },
  {
    path: "merchant/:auth_token/campaign/:campaignId/report",
    component: MerchantCampaignReportComponent
  },
  {
    path: "profile",
    component: UserDetailsComponent,
    data: {
      title: "Profile | Hashtag Loyalty"
    }
  },
  {
    path: "my-gift-card",
    component: GiftCardComponent,
    data: {
      title: "gift card | Hashtag Loyalty"
    }
  },
  {
    path: "my-gift-card/:auth",
    component: GiftCardComponent,
    data: {
      title: "gift card | Hashtag Loyalty"
    }
  },
  {
    path: "edit/profile",
    component: EdituserprofileComponent,
    data: {
      title: "Edit Profile | Hashtag Loyalty"
    }
  },
  {
    path: "edit/profile/:auth",
    component: EdituserprofileComponent,
    data: {
      title: "Edit Profile | Hashtag Loyalty"
    }
  },
  {
    path: "web/feedback/:id/:auth",
    component: WebFeedbackComponent,
    data: {
      title: "Feedback | Hashtag Loyalty"
    }
  },
  {
    path: "web/feedback/:id/:auth/:billnumber",
    component: WebFeedbackComponent,
    data: {
      title: "Feedback | Hashtag Loyalty"
    }
  },
  {
    path: "about-us",
    component: AboutUsComponent,
    data: {
      title: "About Hashtag Loyalty"
    }
  },
  {
    path: "merchant/details/:slug",
    component: MerchantDetailsComponent,
    data: {
      title: "| Hashtag Loyalty"
    }
  },
  {
    path: "merchant/details/:slug/:auth",
    component: MerchantDetailsComponent,
    data: {
      title: "| Hashtag Loyalty"
    }
  },
  {
    path: "merchant/user/reward",
    component: RewardViewComponent,
    data: {
      title: "reward | Hashtag Loyalty"
    }
  },
  {
    path: "merchant/partner-locations",
    component: LocationComponent,
    data: {
      title: "Partner Locations Hashtag Loyalty"
    }
  },
  {
    path: "merchant/partner-locations/campaign/:id",
    component: LocationComponent,
    data: {
      title: "Partner Locations Hashtag Loyalty"
    }
  },
  {
    path: "merchant/partner-locations/chain/:chain_id",
    component: LocationComponent,
    data: {
      title: "Partner Locations Hashtag Loyalty"
    }
  },
  {
    path: "referral/refer/:slug/:auth",
    component: ReferMerchantComponent
  },
  {
    path: "referral/claim/:slug/:auth",
    component: ClaimRewardComponent
  },
  {
    path: "terms-privacy",
    component: TermsPrivacyComponent,
    data: {
      title: "Terms and Privacy | Hashtag Loyalty"
    }
  },
  {
    path: "team",
    component: TeamComponent,
    data: {
      title: "Team at Hashtag Loyalty"
    }
  },
  {
    path: "spin/the/wheel",
    component: SpinTheWheelComponent,
    data: {
      title: "Spin The Wheel Hashtag Loyalty"
    }
  },

  { path: ":sulg/e-bill/:bill_number", component: EBillComponent },
  { path: ":id", component: UrlShortnerComponent },
  { path: "**", redirectTo: "", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
