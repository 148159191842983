import {
  BrowserModule,
  BrowserTransferStateModule
} from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule ,DatePipe} from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// website component
import { HomeComponent } from "./website/home/home.component";
import { NavbarComponent } from "./website/navbar/navbar.component";
// angular flex layout
import { FlexLayoutModule } from "@angular/flex-layout";
// scrolling
import { PlatformModule } from "@angular/cdk/platform";
// angular material package
import { SlickCarouselModule } from "ngx-slick-carousel";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
// angular material module
import {
  MatToolbarModule,
  MatTabsModule,
  MatSidenavModule,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatExpansionModule,
  MatGridListModule,
  MatDialogRef
} from "@angular/material";
import { GenericFeatureCardComponent } from "./website/generic-feature-card/generic-feature-card.component";
import { GenericCarousalCardComponent } from "./website/generic-carousal-card/generic-carousal-card.component";
import { FooterComponent } from "./website/footer/footer.component";
import { GetdemoComponent } from "./website/getdemo/getdemo.component";
import { ReservationComponent } from "./website/reservation/reservation.component";
import { FeedbackComponent } from "./website/feedback/feedback.component";
import { LoyaltyComponent } from "./website/loyalty/loyalty.component";
import { UnderstandComponent } from "./website/understand/understand.component";
import { EngageComponent } from "./website/engage/engage.component";
import { BenefitsComponent } from "./website/benefits/benefits.component";
import { ContactUsComponent } from "./website/contact-us/contact-us.component";
import { HiringComponent } from "./website/hiring/hiring.component";
import { JobDetailComponent } from "./website/job-detail/job-detail.component";
import { LoginComponent } from "./website/login/login.component";
import { MerchantWeeklyReportComponent } from "./website/merchant-weekly-report/merchant-weekly-report.component";
// import services
import { NavbarserviceService } from "./website/navbar/navbarservice.service";
import { ReportService } from "./website/merchant-weekly-report/report.service";
import { WindowRef } from "./window-ref.service";
//import component
import { ImagePopupComponent } from "./website/imagepopupcomponent/image-popup.component";
import { GenericReportCardComponent } from "./website/generic-report-card/generic-report-card.component";
import { ReportFooterComponent } from "./website/report-footer/report-footer.component";
import { GenericReportRowComponent } from "./website/generic-report-row/generic-report-row.component";
import { MerchantCampaignReportComponent } from "./website/merchant-campaign-report/merchant-campaign-report.component";
import { UserDetailsComponent } from "./website/user-details/user-details.component";
import { WebFeedbackComponent } from "./website/web-feedback/web-feedback.component";
import { AboutUsComponent } from "./website/about-us/about-us.component";
import { MerchantDetailsComponent } from "./website/merchant-details/merchant-details.component";
import { DialogpopupComponent } from "./website/dialogpopup/dialogpopup.component";
import { LocationComponent } from "./website/location/location.component";
import { CustomerSpecksComponent } from "./website/customer-specks/customer-specks.component";
import { TermsPrivacyComponent } from "./website/terms-privacy/terms-privacy.component";
import { TeamComponent } from "./website/team/team.component";
import { SafePipe } from "./website/safe-pipe.pipe";
import { FormatTimePipe } from "./website/login/login.component";
import { EdituserprofileComponent } from "./website/edituserprofile/edituserprofile.component";
import { ThankYouPopupComponent } from "./website/thank-you-popup/thank-you-popup.component";
import { UrlShortnerComponent } from "./website/url-shortner/url-shortner.component";
import { SpinTheWheelComponent } from "./website/spin-the-wheel/spin-the-wheel.component";
import { GenericCampaignReportRowComponent } from "./website/generic-campaign-report-row/generic-campaign-report-row.component";
import { GenericCampaignReportCardComponent } from "./website/generic-campaign-report-card/generic-campaign-report-card.component";
import { TransferHttpCacheModule } from "@nguniversal/common";

import { NgtUniversalModule } from "@ng-toolkit/universal";
import { IntegratedSolutionComponent } from "./website/integrated-solution/integrated-solution.component";
import { ReferMerchantComponent } from "./website/refer-merchant/refer-merchant.component";
import { ReferMerchantPopupComponent } from "./website/refer-merchant/refer-merchant-popup/refer-merchant-popup.component";
import { ClaimRewardComponent } from "./website/refer-merchant/claim-reward/claim-reward.component";
import { ClaimRewardPopupComponent } from "./website/refer-merchant/claim-reward/claim-reward-popup/claim-reward-popup.component";
import { EBillComponent } from "./website/e-bill/e-bill.component";

import { InputComponent } from './website/input-components/input/input.component';
import { SubmitButtonComponent } from './website/input-components/submit-button/submit-button.component';
import { SelectComponent } from './website/input-components/select/select.component';
import { DateComponent } from './website/input-components/date/date.component';
import { DynamicFormComponent } from './website/input-components/dynamic-form/dynamic-form.component';
import { DynamicFieldDirective } from './website/input-components/dynamic-field/dynamic-field.directive';
import { communication } from './website/input-components/communication/communication';
import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { PinchZoomModule } from "ngx-pinch-zoom";
import { RewardRowComponent } from './website/merchant-details/reward-row/reward-row.component';
import { RewardViewComponent } from './website/merchant-details/reward-view/reward-view.component';
import { StampRewardComponent } from './website/merchant-details/stamp-reward/stamp-reward.component';
import { GiftCardComponent } from './website/gift-card/gift-card.component';
import { PopupTaxesComponent } from './website/e-bill/popup-taxes/popup-taxes.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto"
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    GenericFeatureCardComponent,
    GenericCarousalCardComponent,
    FooterComponent,
    GetdemoComponent,
    ReservationComponent,
    FeedbackComponent,
    LoyaltyComponent,
    UnderstandComponent,
    EngageComponent,
    BenefitsComponent,
    ContactUsComponent,
    HiringComponent,
    JobDetailComponent,
    LoginComponent,
    MerchantWeeklyReportComponent,
    GenericReportCardComponent,
    ReportFooterComponent,
    GenericReportRowComponent,
    MerchantCampaignReportComponent,
    UserDetailsComponent,
    WebFeedbackComponent,
    AboutUsComponent,
    MerchantDetailsComponent,
    DialogpopupComponent,
    LocationComponent,
    CustomerSpecksComponent,
    TermsPrivacyComponent,
    TeamComponent,
    SafePipe,
    FormatTimePipe,
    EdituserprofileComponent,
    ThankYouPopupComponent,
    UrlShortnerComponent,
    SpinTheWheelComponent,
    GenericCampaignReportRowComponent,
    GenericCampaignReportCardComponent,
    IntegratedSolutionComponent,
    ImagePopupComponent,

    ReferMerchantPopupComponent,
    ClaimRewardPopupComponent,
    ReferMerchantComponent,
    ClaimRewardComponent,
    EBillComponent,

    
    DynamicFieldDirective,
    DynamicFormComponent,
    InputComponent,
    SubmitButtonComponent,
    SelectComponent,
    DateComponent,
    communication,
    RewardRowComponent,
    RewardViewComponent,
    StampRewardComponent,
    GiftCardComponent,
    PopupTaxesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // flex layout
    FlexLayoutModule,
    SlickCarouselModule,
    SwiperModule,
    // angular material module
    MatToolbarModule,
    MatTabsModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    PlatformModule,
    MatDatepickerModule,

    MatNativeDateModule,
    MatCheckboxModule,
    MatExpansionModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
    MatGridListModule,
    PinchZoomModule
  ],
  // export: [MatSidenavModule],
  providers: [
    NavbarserviceService,
    ReportService,
    MatDatepickerModule,
    DatePipe,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    WindowRef
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogpopupComponent,
    ThankYouPopupComponent,
    ReferMerchantPopupComponent,
    ClaimRewardPopupComponent,
    ImagePopupComponent,
    InputComponent,
    SelectComponent,
    DateComponent,
    communication,
    SubmitButtonComponent,
    PopupTaxesComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
