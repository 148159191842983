import { Injectable, Inject } from '@angular/core';
import { map} from 'rxjs/operators';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private url: string = environment.WEB_API;
  name: any;
  headers: object = new HttpHeaders({
    'Content-Type':  'application/json'
  });
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any, public http: HttpClient, private router: Router) { }
  getOTPUser(mobileNumber) {
    return this.http.post( this.url + '/web/api/v2/user/otp', {login_par: mobileNumber}, this.headers);
  }
  
  loginUser(credential) {
    return this.http.post( this.url + '/web/api/v2/user/login', credential , this.headers)
    .pipe(map((user: any) => {
      if (user && user.user.authentication_token) {
        this.localStorage.setItem('auth_token', user.user.authentication_token);
        this.localStorage.setItem('user_details', JSON.stringify(user.user));
        this.name = user.user.fname;
      }
    }));
  }
  logout() {
    this.localStorage.clear();
    this.router.navigate(['/']);
  }
  getToken() {
    return this.localStorage.getItem('auth_token');
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  getUserInfo() {
    if (this.localStorage.getItem('user_details')) {
      return JSON.parse(this.localStorage.getItem('user_details'));
    } else {
      return undefined;
    }
  }
}
