import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engage',
  templateUrl: './engage.component.html',
  styleUrls: ['./engage.component.css']
})
export class EngageComponent implements OnInit {
  features: any;
  activeCarousel: any;
  constructor() { }
  slides = [
    {img: '1'},
    {img: '2'},
    {img: '3'},
    {img: '4'},
    {img: '5'}
  ];
  slideConfig = {'slidesToShow': 3,
  'slidesToScroll': 3,
  // "dots": true,
  'infinite': true,
  'autoplay': true,
  // 'mobileFirst': true,
  // "accessibility": true,
  'centerMode': true,
  'centerPadding': '10px',
  // 'adaptiveHeight': true,
  'autoplaySpeed': 1000,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        autoplay: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    },
    {
      breakpoint: 480,
      settings: {
        autoplay: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    },
    {
      breakpoint: 320,
      settings: {
        autoplay: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    }]
};
  afterChange(e) {
    this.activeCarousel =  e.currentSlide;
  }
  ngOnInit() {
    
    this.features = [{cardImage: 'customer-engagement-campaigns',
                     cardTitle: 'Campaigns' ,
                     alttitle: 'Send targeted informational or reward campaigns',
                     alttext: 'Icon for cross-channel and targeted informational or reward campaigns',
                     cardContent: 'Send cross-channel (SMS, Email) campaigns to customers as per their preferences and targeted based on demographics, RFM or cohorts. Track campaign performance and revenue generated in real-time.'},
                    {cardImage: 'automated-customer-engagement-1',
                     cardTitle: 'Auto Engage',
                     alttitle: 'Setup automated customer engagement communication',
                     alttext: 'Icon for automated customer engagement for every stage of the lifecycle',
                     cardContent: 'Setup automated customer engagement at each stage of the customer lifecycle. Create a brand recall with simple informational or reward campaigns.'},
                    {cardImage: 'customer-referral',
                     cardTitle: 'Referrals',
                     alttitle: 'Implement a referral program',
                     alttext: 'Icon for referral program to acquire new customers',
                     cardContent: 'Acquire customers by leveraging your loyal customer base with a referral program or campaign.'},
                    {cardImage: 'customer-feedback',
                     cardTitle: 'Feedback',
                     alttitle: 'Customize your customer feedback forms',
                     alttext: 'Icon for customized customer feedback forms',
                     cardContent: 'Design your feedback forms with reference templates & questions or customize the same as per your requirements.'},
                    {cardImage: 'hashtag-loyalty-network-partnerships',
                     cardTitle: 'Partnerships',
                     alttitle: 'Partnerships withing the Hashtag Loyalty network',
                     alttext: 'Icon for partnerships with complementing businesses within the Hashtag Loyalty network',
                     cardContent: 'Acquire customers by partnering and sending campaigns to customers of a complementing business on the Hashtag Loyalty Network.'},
                    {cardImage: 'customer-engagement-journey-builder',
                     cardTitle: 'Journey Builder',
                     alttitle: 'Create unique engagement journeys for customers',
                     alttext: 'Icon for journey builder to create unique customer engagement ',
                     cardContent: 'Create unique customer engagement workflows targeted based on demographics, RFM, etc. and triggered by activity such as purchases, opens, clicks, etc.'}];
  }
}
