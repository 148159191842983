import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  investors: any;
  advisors: any;
  press: any;
  constructor() { }
  ngOnInit() {
    this.investors = [{image: 'thinQbate-startup-incubator', title: 'Backed by thinQbate', alt:'Logo of thinQbate' ,class:'thinq', content: 'An incubator that aims to create a conveyor belt of scalable and growth oriented businesses, by providing an entrepreneur and community centric approach to incubation and angel investing, with a focus on giving individuals the autonomy and resources to grow.'},
    {image: 'ahimsa-capital', title: 'Backed by Ahimsa Capital', alt:'Logo of Ahimsa Campital',class:'ahimsa', content: 'An global investment fund actively focusing on food, hospitality & technology investments in India. Led by Anish Malhotra, a serial entrepreneur and investor. He has founded and started several hospitality businesses in India & United States.'}];
    this.advisors = [{image: 'anish-malhotra-profile', title: 'Anish Malhotra',alt:'Profile image of Anish Malhotra', content: 'Managing Partner,<br/> Ahimsa Capital'},
                    {image: 'ludivine-noirel-profile', title: 'Ludivine Noirel',alt:'Profile image of Ludivine Noirel', content: 'Partner,<br/> Ahimsa Capital'},
                    {image: 'sauvik-banerjee-profile', title: 'Sauvik Banerjee',alt:'Profile image of Sauvik Banerjee', content: 'VP, Tata Industries and CTO, Tata Cliq'}];
    this.press = [{image: 'bw-disrupt', title:'Featured in BW Disrupt',alt:'Logo of BW Disrupt', link: 'http://bwdisrupt.businessworld.in/article/CRM-and-Marketing-Automation-Platform-Hashtag-Loyalty-Raises-Seed-Funding/27-06-2018-153176/'},
                  {image: 'et-rise',title:'Featured in ET Rise',alt:'Logo of ET Rise', link: 'https://economictimes.indiatimes.com/small-biz/startups/how-startups-are-helping-brands-to-improve-user-engagement/articleshow/52482155.cms?from=mdr'},
                  {image: 'inc-42',title:'Featured in Inc42',alt:'Logo of Inc42', link: 'https://inc42.com/startups/exclusive-crm-startup-hashtag-loyalty-raises-funding-to-focus-into-ai-powered-marketing/'},
                  {image: 'your-story',title:'Featured in YourStory',alt:'Logo of Your Story', link: 'https://yourstory.com/2017/02/300k-transactions-counting-hashtag-loyalty-disrupting-offline-businesses-market'}];
  }

}
