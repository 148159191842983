import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AsyncApiCallHelperService } from "../asyncapicallhepler.service";

@Injectable({
  providedIn: "root"
})
export class LocationService {
  headertype = new HttpHeaders({
    "Content-Type": "application/json"
  });
  private url: string = environment.WEB_API;
  constructor(
    public http: HttpClient,
    private processor: AsyncApiCallHelperService
  ) {}
  getCityList(id) {
    if (id) {
      let params;
      if (id.campaign_id) {
        params = { campaign_id: id.campaign_id };
      } else {
        params = { chain_id: id.chain_id };
      }
      return this.http.get(this.url + "/web/api/v2/locations/cities", {
        headers: this.headertype,
        params
      });
    } else {
      return this.http.get(this.url + "/web/api/v2/locations/cities", {
        headers: this.headertype
      });
    }
  }
  getLocationByBrand(params) {
    return this.http.get(this.url + "/web/api/v2/locations/", {
      headers: this.headertype,
      params
    });
  }
  getLocationMerchant(params, header) {
    return this.processor.doTask(
      this.http.get(this.url + "/web/api/v2/locations/" + params["id"], {
        headers: header
      })
    );
  }
  getLocations(params) {
    const locationPoints = params;
    return this.http.get(this.url + "/web/api/v2/locations/lat-lng", {
      headers: this.headertype,
      params: locationPoints
    });
  }
}
