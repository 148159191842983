import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-privacy',
  templateUrl: './terms-privacy.component.html',
  styleUrls: ['./terms-privacy.component.css']
})
export class TermsPrivacyComponent implements OnInit {

  constructor() { }
  ngOnInit() {

  }

}
