import { Component, OnInit ,Inject} from '@angular/core';
import {ProfileService} from '../user-details/profile.service';
import { ActivatedRoute, Router } from "@angular/router";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.css']
})
export class GiftCardComponent implements OnInit {

  constructor(private userProfileService:ProfileService, 
              private route: ActivatedRoute,
              @Inject(LOCAL_STORAGE) private localStorage: any) { }
  giftCards:any;
  auth_token:any;
  ngOnInit() {
    this.route.params.forEach(urlParams => {
      this.auth_token = urlParams["auth"];
      if(this.auth_token){
        this.localStorage.setItem("auth_token", this.auth_token);
      this.userProfileService.getUserInfo().subscribe(
        (data: any) => {
          this.localStorage.setItem("user_name", data.user.fname);
          this.localStorage.setItem("user_details", JSON.stringify(data.user));
        },
        _error => {
          this.localStorage.clear();
        }
      );
      }
    }); 
    this.userProfileService.getGiftCard().subscribe((data:any)=>{
      this.giftCards = data;
    });
  }

}
