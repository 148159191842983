import {Component,OnInit,Inject,PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocationService } from "../../website/location/location.service";
import { LoginService } from "../login/login.service";
import { HttpHeaders } from "@angular/common/http";
import { Validators } from "@angular/forms";
import { ImagePopupComponent } from "../imagepopupcomponent/image-popup.component";
import { MatDialog } from "@angular/material";
import { NavbarserviceService } from "../navbar/navbarservice.service";
import { Title } from "@angular/platform-browser";
import { ProfileService } from "../user-details/profile.service";
import { EdituserprofileService} from '../edituserprofile/edituserprofile.service';
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { EdituserprofileComponent } from "../edituserprofile/edituserprofile.component";
import { isPlatformBrowser } from "@angular/common";
import { DatePipe } from '@angular/common'
@Component({
  selector: "app-merchant-details",
  templateUrl: "./merchant-details.component.html",
  styleUrls: ["./merchant-details.component.css"]
})
export class MerchantDetailsComponent implements OnInit {
  currentImage: any;
  editProfile: any;
  dialogRef: any;
  testBrowser: any;
  totalRewardLength:any;
  rewardType:string;
  authView:any=[];
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
              private locationService: LocationService,
              private route: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private profileService: ProfileService,
              private titleService: Title,
              private navbarService: NavbarserviceService,
              private editProfileService:EdituserprofileService,
              public dialog: MatDialog,
              @Inject(PLATFORM_ID) platformId: string,
              public datepipe: DatePipe) {
                this.testBrowser = isPlatformBrowser(platformId);
              }

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: true,
    variableWidth: true,
    infinite: true,
    autoplay: true,
    centerMode: false,
    centerPadding: "10px",
    autoplaySpeed: 700000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          centerMode: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false
        }
      },

      {
        breakpoint: 400,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false
        }
      }
    ]
  };
  slug: any;
  merchantData: any;
  fragment: string;
  isFeedbackHidden: boolean = false;
  auth: any;
  header: any;
  auth_token: any;
  showImages: any = {
    menu_images: false,
    creative_images: false
  };
  profileForm:any=[];
  toggleSection:boolean = false;
  specialReward:any;
  normalReward:any;
  stampData:any;
  ngOnInit() {
    this.route.params.forEach(urlParams => {
      this.slug = urlParams["slug"];
      if (urlParams["auth"]) {
        this.auth = urlParams["auth"].split("?")[0];
        this.editProfile =
          urlParams["auth"].split("=")[1] === "true" ? true : false;
      }
      if (this.slug) {
        this.titleService.setTitle(this.slug + " | Hashtag Loyalty");
      }
    });
    
    this.auth_token = this.loginService.getToken();
    const params = { id: this.slug };
    if (this.auth_token && !this.auth) {
      this.navbarService.show();
      this.header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.auth_token
      });
      this.profileService.getUserInfo().subscribe((data: any) => {
          this.localStorage.setItem("user_name", data.user.fname);
          this.localStorage.setItem("user_details", JSON.stringify(data.user));
      },_error => {
          this.localStorage.clear();
      });
    } else if (this.auth) {
      this.header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.auth
      });
      this.localStorage.setItem("auth_token", this.auth);
      this.profileService.getUserInfo().subscribe((data: any) => {
        this.localStorage.setItem("user_name", data.user.fname);
        this.localStorage.setItem("user_details", JSON.stringify(data.user));
      },_error => {
        this.localStorage.clear();
      });
    } else {
      this.header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: ""
      });
    }
    this.businessView(params, this.header);
    
  }
  checkURL(url) {
    let s = document.createElement('img');
    s.src = url
    s.onerror = function() {
      let i = document.getElementById('idbg');
      i.style.backgroundImage = "url('https://hashtagloyalty.s3-ap-southeast-1.amazonaws.com/WebsiteAssets/generic-bg-image.png')";
    }
    s.onload = function() {
      let i = document.getElementById('idbg');
      i.style.backgroundImage = "url('"+url+"')";
    } 
 }
  
  jumpToContactUs() {
    document.getElementById("contactNo").scrollIntoView();
  }

  businessView(params, header) {
    this.locationService.getLocationMerchant(params, header).subscribe((merchant: any) => {
        this.merchantData = merchant.merchant;
        if(this.merchantData.bonus_points_rewards.length){
          this.merchantData.bonus_points_rewards.forEach(element => {
            if(element.expiry_date){
              let date = new Date();
              date.setDate( date.getDate() + element.expiry_date );
              element.expiry_date = date;
            }
          });
        }
        if(this.merchantData.user_visited && this.merchantData.total_points !== null){
          if(!Object.keys(this.merchantData.stamp_data).length){
            let point = this.merchantData.total_points;
            let amount = this.merchantData.amount_spent;
            let date = new Date();
            date.setDate( date.getDate() + this.merchantData.loyalty_point_expiry );
            let expiry = this.datepipe.transform(date,'dd MMM, y');
            switch (this.merchantData.is_cashback_model) {
              
              case true:

                switch (this.merchantData.m_type) {
                  case 'spent':
                    this.authView.push({'name':'Total cashback','value':'₹'+`${point}`,'expiry_msg': expiry ? 'Cashback expire on '+`${expiry}`:'','link':'How to redeem?'});
                  break;
                  case 'visit':
                    this.authView.push({'name':'Total cashback','value':'₹'+`${point}`,'expiry_msg': expiry ? 'Cashback expire on '+`${expiry}`:'','link':'How to redeem?'}); 
                  break;
                  case 'retail':
                    this.authView.push({'name':'Total cashback','value':'₹'+`${point}`,'expiry_msg': expiry ? 'Cashback expire on '+`${expiry}`:'','link':'How to redeem?'},
                                     {'name':'Total amount spent','value':'₹'+`${amount}`},
                                     {'name':'Current tier','value':this.merchantData.current_tier});
                  break;
                }

              break;

              case false:

                switch (this.merchantData.m_type) {
                  case 'spent':
                  this.authView.push({'name':'Total points balance','value':`${point}`+' pts','expiry_msg': expiry ? 'Points expire on '+`${expiry}`:'','link':'How to redeem?'});
                  break;

                  case 'visit':
                  this.authView.push({'name':'Total points balance','value':`${point}`+' pts','expiry_msg': expiry ? 'Points expire on '+`${expiry}`:'','link':'How to redeem?'});
                  break;

                  case 'retail':
                  this.authView.push({'name':'Total points balance','value':`${point}`+' pts','expiry_msg': expiry ? 'Points expire on '+`${expiry}`:'','link':'How to redeem?'},
                                    {'name':'Total amount spent','value':'₹'+`${amount}`},
                                    {'name':'Current tier','value':this.merchantData.current_tier});
                  break;
                }

              break;
            } 
          }
        } 
        if(this.merchantData.user_visited && this.merchantData.total_points === null && this.merchantData.m_type === 'retail'){
          this.authView.push({'name':'Total amount spent','value':'₹'+`${this.merchantData.amount_spent}`},
          {'name':'Current tier','value':this.merchantData.current_tier});  
        }
        
        this.merchantData.special_rewards;
        let rewardLength;
        this.merchantData.special_rewards ? rewardLength = this.merchantData.special_rewards.length : rewardLength = this.merchantData.special_rewards.length;

        if(this.merchantData.special_rewards.length){
          this.localStorage.setItem('special_reward',JSON.stringify(this.merchantData.special_rewards));
        } else{
          this.localStorage.removeItem("special_reward");
        } 

        if( this.merchantData.rewards.length &&
          !this.merchantData.is_cashback_model &&
          (!Object.keys(this.merchantData.stamp_data).length)){            
          this.rewardType='normal';
          this.localStorage.setItem('normal_reward',JSON.stringify(this.merchantData.rewards));
          this.localStorage.removeItem("stamp_reward");
        } else if((Object.keys(this.merchantData.stamp_data).length!== 0) && 
                  !this.merchantData.is_cashback_model) {
          this.rewardType='stamp';
          this.localStorage.setItem('stamp_reward',JSON.stringify(this.merchantData.stamp_data));
          this.localStorage.removeItem("normal_reward");
        } else if(this.merchantData.is_cashback_model && 
                  (!Object.keys(this.merchantData.stamp_data).length && this.merchantData.special_rewards.length)){
          this.rewardType='cashback';
          this.localStorage.removeItem("normal_reward");
          this.localStorage.removeItem("stamp_reward");
        }

        if(this.merchantData.rewards.length &&
          !this.merchantData.is_cashback_model &&
          (!Object.keys(this.merchantData.stamp_data).length)){
          if(this.merchantData.special_rewards.length < 6){
          this.specialReward = this.merchantData.special_rewards;
          } else{
            this.specialReward = this.merchantData.special_rewards.slice(0,5);
          } 
          this.totalRewardLength = rewardLength + this.merchantData.rewards.length;
          if(rewardLength < 6){
            let value = 5 - rewardLength;
            this.normalReward = this.merchantData.rewards.slice(0,value);
          } else{
            this.normalReward = [];
          }
        }

        if (Object.keys(this.merchantData.stamp_data).length !== 0){
          this.stampData = Object.assign({},this.merchantData.stamp_data);
          this.specialReward = this.merchantData.special_rewards.slice(0,3); 
          if(this.merchantData.stamp_data.earned_stamps > 9){
            this.totalRewardLength = 10;
            this.localStorage.setItem('stamp_reward',JSON.stringify(this.merchantData.stamp_data));
            this.stampData['earned_stamps'] = 8;
          } else{
            this.localStorage.removeItem("stamp_reward");
          } 
        } else {
          this.localStorage.removeItem("stamp_reward");
        } 

        this.checkURL(this.merchantData.background_image);
        // this.merchantData.menu_images.forEach(image => {
        //   image.url = image.url.replace("dev", "");
        // });
        // this.merchantData.creative_images.forEach(image => {
        //   image.url = image.url.replace("dev", "");
        // });
        this.localStorage.setItem("merchantData",JSON.stringify(this.merchantData));
        let CurrentTierName = this.merchantData.current_tier;
        
        if (this.merchantData.type &&
            Array.isArray(this.merchantData.type) &&
            this.merchantData.type.length > 0) {
          let tierArray = this.merchantData.type;
          let boxSize = 100 / tierArray.length;
          let amount_spent = this.merchantData.amount_spent;
          tierArray.forEach((tier, idx) => {
            tier.tierWidth = boxSize;
            let i = idx === tierArray.length - 1 ? idx : idx + 1;
            if (amount_spent > tierArray[i].amount) {
              tier.progress = { width: "100%" };
            } else {
              let w = Math.round(((amount_spent - tier.amount) / (tierArray[i].amount - tier.amount)) * 100);
              w = w < 0 ? 0 : w;
              tier.progress = { width: w + "%" };
            }
            let itemAmount = parseInt(tier.amount) ? parseInt(tier.amount) + 1 : 0;
            idx === tierArray.length - 1 ? (tier.amount_text = tier.tier_name + " (₹" +
                  this.merchantData.type[i].amount + "+)") : (tier.amount_text = tier.tier_name +
                  " (₹" + itemAmount + " -  ₹" +this.merchantData.type[i].amount + ")");
            if (tier.tier_name === CurrentTierName && idx !== tierArray.length - 1) {
              let amountDiff = parseInt(this.merchantData.type[i].amount) - amount_spent + 1;
              this.merchantData.progressText = "₹" + amountDiff + " away from " + this.merchantData.type[i].tier_name;
            }
          });
        }
        if (this.editProfile) {
          setTimeout(() => {
            this.editProfileService.getFormKeyById(this.merchantData.id).subscribe((data:any)=>{
              this.profileForm = Object.assign([],data);
              this.handleCompleteProfile();
            })
          }, 500);
        }
      },_error => {
        this.router.navigateByUrl("/");
      }
    );
  }

  handleCompleteProfile() {
    if (this.testBrowser) {
      let data: any = {};
      let config: any = {};
      data.profileForm = Object.assign([],this.profileForm);
      data.editProfile = true;
      data.slug = this.slug;
      data.merchantName = this.merchantData.name;
      config.height = "auto";
      config.minWidth = "60vw";
      config.disableClose = true;
      config.closeOnNavigation = true;
      config.data = data;
      config.maxWidth = "90vw";
      config.panelClass = 'edit-profile-dialog-container';
      this.dialogRef = this.dialog.open(EdituserprofileComponent, config);
      this.dialogRef.afterClosed().subscribe(result => {});
    }
  }
  hideFeedback() {
    this.isFeedbackHidden = true;
  }
  goTo(section: string) {
    switch (section) {
      case "locations":
        this.router.navigateByUrl("/merchant/location");
        break;
      case "profile":
        this.router.navigateByUrl("/profile");
        break;
    }
  }
  mapUrl() {
    return (
      "https://maps.google.com/maps?q=" +
      encodeURIComponent(this.merchantData.name) +
      encodeURIComponent(this.merchantData.location) +
      "&t=&z=13&ie=UTF8&iwloc=&output=embed"
    );
  }

  afterChange(event) {
    this.currentImage = event.currentSlide;
  }

  detectMob() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }

  viewImage(image, type) {
    image.type = type;
    this.currentImage = image;
    this.dialogRef = this.dialog.open(ImagePopupComponent, {
      height: "auto",
      maxHeight: "100vh",
      minWidth: "30vw",
      width: this.detectMob() ? "90%" : "700px",
      closeOnNavigation: true,
      data: this.detectMob()
        ? {
            images: this.merchantData[type],
            currentIndex: this.merchantData[type].indexOf(image),
            type
          }
        : image
    });

    this.dialogRef.componentInstance.rightArrowClicked.subscribe(type => {
      let nextImage = this.merchantData[type][
        this.merchantData[type].indexOf(this.currentImage) + 1
      ];
      if (nextImage) {
        nextImage.type = type;
        this.dialogRef.componentInstance.data = nextImage;
        this.currentImage = nextImage;
      }
    });

    this.dialogRef.componentInstance.leftArrowClicked.subscribe(type => {
      let nextImage = this.merchantData[type][
        this.merchantData[type].indexOf(this.currentImage) - 1
      ];
      if (nextImage) {
        nextImage.type = type;
        this.dialogRef.componentInstance.data = nextImage;
        this.currentImage = nextImage;
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {});
  }
  toggleButton(value){
    this.toggleSection = value ? false : true; 
  }
}