import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  team: any;
  activetab: any;
  constructor() { }

  ngOnInit() {
    this.team = [{name: 'Krishi Fagwani', role: '#Co-Founder', image:'krishi', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Karan Chechani', role: '#Co-Founder', image:'karan', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Dhruv Diwan', role: '#Co-Founder', image:'dhruv', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Avani Ashar', role: '#TeamBusinessDevelopment', image:'avani', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Anant Raniwala', role: '#TeamBusinessDevelopment', image:'anant', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Aayush Parikh', role: '#TeamBusinessDevelopment', image:'aayush', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Aditi Thakur', role: '#TechnologyTeam', image:'aditi', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Sarmishta Velury', role: '#TeamDataScience', image:'sarmishta', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Bhupendra Pal', role: '#TechnologyTeam', image:'bhupendra', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Shreshtha Gupta', role: '#TeamBusinessDevelop', image:'shreshtha', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Vishaka Saboo', role: '#TeamBusinessDevelopment', image:'vishaka', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Pranshu Diwan', role: '#TeamDataScience', image:'pranshu', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Sumesh Marya', role: '#TeamBusinessDevelopment', image:'sumesh', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Yogen Aggarwal', role: '#TechnologyTeam', image:'yogen', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Sanket Mowar', role: '#TeamBusinessDevelopment', image:'sanket', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Vijendra Singh', role: '#TeamDataScience', image:'vijendra', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Avantika Aggarwal', role: '#TechnologyTeam', image:'avantika', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Shubhra Kalia', role: '#TeamBusinessDevelopment', image:'shubhra', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Manish  Kunder', role: '#TeamBusinessDevelopment', image:'manish', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'},
    {name: 'Rohan Moona', role: '#TeamMarketting', image:'rohan', about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s,'}
  ];
  }
  onclickcard(i) {
this.activetab = i;
  }
}
