import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.css']
})
export class HiringComponent implements OnInit {
  job: any;
  constructor() { }

  ngOnInit() {
    this.job = [{id: 1, jobtitle: 'Business Development Associate'},
                {id: 2, jobtitle: 'Business Development Associate (Pune)'},
                {id: 3, jobtitle: 'Business Development Manager (Pune)'},
                {id: 4, jobtitle: 'Data Scientist'},
                {id: 5, jobtitle: 'Enterprise Account Manager - Gaming and F&B'},
                {id: 6, jobtitle: 'Front End Developer'},
                {id: 7, jobtitle: 'Key Accounts Manager'},
                {id: 8, jobtitle: 'Marketing Maven'},
                {id: 9, jobtitle: 'Product Manager'},
                {id: 10, jobtitle: 'Sr. Ruby on Rails Engineer'}
              ];
  }
  onclickApply(id) {
    console.log(id);
  }

}
