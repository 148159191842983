import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ContactUsService} from './contact-us.service';
import {NavbarserviceService} from '../navbar/navbarservice.service';
import {Router, ActivatedRoute} from '@angular/router';
declare var hj;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  submitForm: FormGroup;
  submitted = false;
  queryType: any;
  querySource: any;
  is_disabled: any;
  addControl: any;
  dialogRef: any;
  mobileno: any;
  constructor(private formBuilder: FormBuilder, private contactService: ContactUsService,
    private navbarService: NavbarserviceService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.mobileno = this.activeRoute.snapshot.params.mobileno;
    this.queryType = ['Explore Hashtag Loyalty for your Business', 'Become a Hashtag Loyalty Reseller','Work at Hashtag Loyalty'];
    this.querySource = ['Another Business', 'Google', 'Instagram/FB', 'Referral', 'Email', 'Others (please specify)'];
    this.submitForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: [this.mobileno, [Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]*$'), Validators.maxLength(10)]],
      subject: [this.mobileno ? this.queryType[0] :'', [Validators.required]],
      business_name: ['', [Validators.required]],
      source: ['', [Validators.required]],
      other_source: ['', [Validators.required]],
      message: [''],
    });
  }
   // convenience getter for easy access to form fields
  get f() { return this.submitForm.controls; }
  // keyPress(event: any) {
  //   const pattern = /[0-9\+\-\ ]/;
  //   const inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode !== 8 && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // }
  onChangeType(event) {
    if (this.submitForm.value.subject === this.queryType[0]) {
      this.submitForm.addControl('business_name', this.formBuilder.control('', Validators.required));
    } else {
      this.submitForm.removeControl('business_name');
    }

  }
  onChangeSource(event) {
    if (this.submitForm.value.source === this.querySource[5]) {
      this.submitForm.addControl('other_source', this.formBuilder.control('', Validators.required));
    } else {
      this.submitForm.removeControl('other_source');
    }
  }
  onSubmit(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.submitForm.valid) {
      this.is_disabled = true;
      this.contactService.submitInquiry(this.submitForm.value).subscribe((data: any) => {
        // hotjar virtual page view code
        hj('vpv', '/contactus/success');
        let popup = {type: 'Thank you for inquiring with Hashtag Loyalty! We\'ll contact you shortly', image: 'thankyou',id:'contact_us'};
        this.navbarService.openDialog(popup);
        setTimeout(() => {
          // this.navbarService.dialogRef.close();
          this.router.navigate(['/']);
     }, 2500);
      }, error => {
        this.is_disabled = false;
        let popup = {type: 'Oops! Something went wrong, please try again after some time.', image: 'error'};
        this.navbarService.openDialog(popup);
        // setTimeout(() => {
          // this.navbarService.dialogRef.close();
    //  }, 2500);
      });
    }
  }
}
