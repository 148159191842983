import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
@Component({
  selector: 'app-getdemo',
  templateUrl: './getdemo.component.html',
  styleUrls: ['./getdemo.component.css']
})
export class GetdemoComponent implements OnInit {
  submitForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder, private router: Router) { }
  selectedOption: any;
  countryCode: any;
  ngOnInit() {
    // this.countryCode = [{"name":"Israel","dial_code":"+972"},
    // {"name":"Afghanistan","dial_code":"+93"},
    // {"name":"Albania","dial_code":"+355"},
    // {"name":"Algeria","dial_code":"+213"},
    // {"name":"AmericanSamoa","dial_code":"+1 684"},
    // {"name":"Andorra","dial_code":"+376"},
    // {"name":"Angola","dial_code":"+244"},
    // {"name":"Anguilla","dial_code":"+1 264"},
    // {"name":"Antigua and Barbuda","dial_code":"+1268"},
    // {"name":"Argentina","dial_code":"+54"},
    // {"name":"Armenia","dial_code":"+374"},
    // {"name":"Aruba","dial_code":"+297"},
    // {"name":"Australia","dial_code":"+61"},
    // {"name":"Austria","dial_code":"+43"},
    // {"name":"Azerbaijan","dial_code":"+994"},
    // {"name":"Bahamas","dial_code":"+1 242"},
    // {"name":"Bahrain","dial_code":"+973"},
    // {"name":"Bangladesh","dial_code":"+880"},
    // {"name":"Barbados","dial_code":"+1 246"},
    // {"name":"Belarus","dial_code":"+375"},
    // {"name":"Belgium","dial_code":"+32"},
    // {"name":"Belize","dial_code":"+501"},
    // {"name":"Benin","dial_code":"+229"},
    // {"name":"Bermuda","dial_code":"+1 441"},
    // {"name":"Bhutan","dial_code":"+975"},
    // {"name":"Bosnia and Herzegovina","dial_code":"+387"},
    // {"name":"Botswana","dial_code":"+267"},
    // {"name":"Brazil","dial_code":"+55"},
    // {"name":"British Indian Ocean Territory","dial_code":"+246"},
    // {"name":"Bulgaria","dial_code":"+359"},
    // {"name":"Burkina Faso","dial_code":"+226"},
    // {"name":"Burundi","dial_code":"+257"},
    // {"name":"Cambodia","dial_code":"+855"},
    // {"name":"Cameroon","dial_code":"+237"},
    // {"name":"Canada","dial_code":"+1"},
    // {"name":"Cape Verde","dial_code":"+238"},
    // {"name":"Cayman Islands","dial_code":"+ 345"},
    // {"name":"Central African Republic","dial_code":"+236"},
    // {"name":"Chad","dial_code":"+235"},
    // {"name":"Chile","dial_code":"+56"},
    // {"name":"China","dial_code":"+86"},
    // {"name":"Christmas Island","dial_code":"+61"},
    // {"name":"Colombia","dial_code":"+57"},
    // {"name":"Comoros","dial_code":"+269"},
    // {"name":"Congo","dial_code":"+242"},
    // {"name":"Cook Islands","dial_code":"+682"},
    // {"name":"Costa Rica","dial_code":"+506"},
    // {"name":"Croatia","dial_code":"+385"},
    // {"name":"Cuba","dial_code":"+53"},
    // {"name":"Cyprus","dial_code":"+537"},
    // {"name":"Czech Republic","dial_code":"+420"},
    // {"name":"Denmark","dial_code":"+45"},
    // {"name":"Djibouti","dial_code":"+253"},
    // {"name":"Dominica","dial_code":"+1 767"},
    // {"name":"Dominican Republic","dial_code":"+1 849"},
    // {"name":"Ecuador","dial_code":"+593"},
    // {"name":"Egypt","dial_code":"+20"},
    // {"name":"El Salvador","dial_code":"+503"},
    // {"name":"Equatorial Guinea","dial_code":"+240"},
    // {"name":"Eritrea","dial_code":"+291"},
    // {"name":"Estonia","dial_code":"+372"},
    // {"name":"Ethiopia","dial_code":"+251"},
    // {"name":"Faroe Islands","dial_code":"+298"},
    // {"name":"Fiji","dial_code":"+679"},
    // {"name":"Finland","dial_code":"+358"},
    // {"name":"France","dial_code":"+33"},
    // {"name":"French Guiana","dial_code":"+594"},
    // {"name":"French Polynesia","dial_code":"+689"},
    // {"name":"Gabon","dial_code":"+241"},
    // {"name":"Gambia","dial_code":"+220"},
    // {"name":"Georgia","dial_code":"+995"},
    // {"name":"Germany","dial_code":"+49"},
    // {"name":"Ghana","dial_code":"+233"},
    // {"name":"Gibraltar","dial_code":"+350"},
    // {"name":"Greece","dial_code":"+30"},
    // {"name":"Greenland","dial_code":"+299"},
    // {"name":"Grenada","dial_code":"+1 473"},
    // {"name":"Guadeloupe","dial_code":"+590"},
    // {"name":"Guam","dial_code":"+1 671"},
    // {"name":"Guatemala","dial_code":"+502"},
    // {"name":"Guinea","dial_code":"+224"},
    // {"name":"Guinea-Bissau","dial_code":"+245"},
    // {"name":"Guyana","dial_code":"+595"},
    // {"name":"Haiti","dial_code":"+509"},
    // {"name":"Honduras","dial_code":"+504"},
    // {"name":"Hungary","dial_code":"+36"},
    // {"name":"Iceland","dial_code":"+354"},
    // {"name":"India","dial_code":"+91"},
    // {"name":"Indonesia","dial_code":"+62"},
    // {"name":"Iraq","dial_code":"+964"},
    // {"name":"Ireland","dial_code":"+353"},
    // {"name":"Israel","dial_code":"+972"},
    // {"name":"Italy","dial_code":"+39"},
    // {"name":"Jamaica","dial_code":"+1 876"},
    // {"name":"Japan","dial_code":"+81"},
    // {"name":"Jordan","dial_code":"+962"},
    // {"name":"Kazakhstan","dial_code":"+7 7"},
    // {"name":"Kenya","dial_code":"+254"},
    // {"name":"Kiribati","dial_code":"+686"},
    // {"name":"Kuwait","dial_code":"+965"},
    // {"name":"Kyrgyzstan","dial_code":"+996"},
    // {"name":"Latvia","dial_code":"+371"},
    // {"name":"Lebanon","dial_code":"+961"},
    // {"name":"Lesotho","dial_code":"+266"},
    // {"name":"Liberia","dial_code":"+231"},
    // {"name":"Liechtenstein","dial_code":"+423"},
    // {"name":"Lithuania","dial_code":"+370"},
    // {"name":"Luxembourg","dial_code":"+352"},
    // {"name":"Madagascar","dial_code":"+261"},
    // {"name":"Malawi","dial_code":"+265"},
    // {"name":"Malaysia","dial_code":"+60"},
    // {"name":"Maldives","dial_code":"+960"},
    // {"name":"Mali","dial_code":"+223"},
    // {"name":"Malta","dial_code":"+356"},
    // {"name":"Marshall Islands","dial_code":"+692"},
    // {"name":"Martinique","dial_code":"+596"},
    // {"name":"Mauritania","dial_code":"+222"},
    // {"name":"Mauritius","dial_code":"+230"},
    // {"name":"Mayotte","dial_code":"+262"},
    // {"name":"Mexico","dial_code":"+52"},
    // {"name":"Monaco","dial_code":"+377"},
    // {"name":"Mongolia","dial_code":"+976"},
    // {"name":"Montenegro","dial_code":"+382"},
    // {"name":"Montserrat","dial_code":"+1664"},
    // {"name":"Morocco","dial_code":"+212"},
    // {"name":"Myanmar","dial_code":"+95"},
    // {"name":"Namibia","dial_code":"+264"},
    // {"name":"Nauru","dial_code":"+674"},
    // {"name":"Nepal","dial_code":"+977"},
    // {"name":"Netherlands","dial_code":"+31"},
    // {"name":"Netherlands Antilles","dial_code":"+599"},
    // {"name":"New Caledonia","dial_code":"+687"},
    // {"name":"New Zealand","dial_code":"+64"},
    // {"name":"Nicaragua","dial_code":"+505"},
    // {"name":"Niger","dial_code":"+227"},
    // {"name":"Nigeria","dial_code":"+234"},
    // {"name":"Niue","dial_code":"+683"},
    // {"name":"Norfolk Island","dial_code":"+672"},
    // {"name":"Northern Mariana Islands","dial_code":"+1 670"},
    // {"name":"Norway","dial_code":"+47"},
    // {"name":"Oman","dial_code":"+968"},
    // {"name":"Pakistan","dial_code":"+92"},
    // {"name":"Palau","dial_code":"+680"},
    // {"name":"Panama","dial_code":"+507"},
    // {"name":"Papua New Guinea","dial_code":"+675"},
    // {"name":"Paraguay","dial_code":"+595"},
    // {"name":"Peru","dial_code":"+51"},
    // {"name":"Philippines","dial_code":"+63"},
    // {"name":"Poland","dial_code":"+48"},
    // {"name":"Portugal","dial_code":"+351"},
    // {"name":"Puerto Rico","dial_code":"+1 939"},
    // {"name":"Qatar","dial_code":"+974"},
    // {"name":"Romania","dial_code":"+40"},
    // {"name":"Rwanda","dial_code":"+250"},
    // {"name":"Samoa","dial_code":"+685"},
    // {"name":"San Marino","dial_code":"+378"},
    // {"name":"Saudi Arabia","dial_code":"+966"},
    // {"name":"Senegal","dial_code":"+221"},
    // {"name":"Serbia","dial_code":"+381"},
    // {"name":"Seychelles","dial_code":"+248"},
    // {"name":"Sierra Leone","dial_code":"+232"},
    // {"name":"Singapore","dial_code":"+65"},
    // {"name":"Slovakia","dial_code":"+421"},
    // {"name":"Slovenia","dial_code":"+386"},
    // {"name":"Solomon Islands","dial_code":"+677"},
    // {"name":"South Africa","dial_code":"+27"},
    // {"name":"South Georgia and the South Sandwich Islands","dial_code":"+500"},
    // {"name":"Spain","dial_code":"+34"},
    // {"name":"Sri Lanka","dial_code":"+94"},
    // {"name":"Sudan","dial_code":"+249"},
    // {"name":"Suriname","dial_code":"+597"},
    // {"name":"Swaziland","dial_code":"+268"},
    // {"name":"Sweden","dial_code":"+46"},
    // {"name":"Switzerland","dial_code":"+41"},
    // {"name":"Tajikistan","dial_code":"+992"},
    // {"name":"Thailand","dial_code":"+66"},
    // {"name":"Togo","dial_code":"+228"},
    // {"name":"Tokelau","dial_code":"+690"},
    // {"name":"Tonga","dial_code":"+676"},
    // {"name":"Trinidad and Tobago","dial_code":"+1 868"},
    // {"name":"Tunisia","dial_code":"+216"},
    // {"name":"Turkey","dial_code":"+90"},
    // {"name":"Turkmenistan","dial_code":"+993"},
    // {"name":"Turks and Caicos Islands","dial_code":"+1 649"},
    // {"name":"Tuvalu","dial_code":"+688"},
    // {"name":"Uganda","dial_code":"+256"},
    // {"name":"Ukraine","dial_code":"+380"},
    // {"name":"United Arab Emirates","dial_code":"+971"},
    // {"name":"United Kingdom","dial_code":"+44"},
    // {"name":"United States","dial_code":"+1"},
    // {"name":"Uruguay","dial_code":"+598"},
    // {"name":"Uzbekistan","dial_code":"+998"},
    // {"name":"Vanuatu","dial_code":"+678"},
    // {"name":"Wallis and Futuna","dial_code":"+681"},
    // {"name":"Yemen","dial_code":"+967"},
    // {"name":"Zambia","dial_code":"+260"},
    // {"name":"Zimbabwe","dial_code":"+263"},
    // {"name":"land Islands","dial_code":""},
    // {"name":"Antarctica","dial_code":null},
    // {"name":"Bolivia, Plurinational State of","dial_code":"+591"},
    // {"name":"Brunei Darussalam","dial_code":"+673"},
    // {"name":"Cocos (Keeling) Islands","dial_code":"+61"},
    // {"name":"Congo, The Democratic Republic of the","dial_code":"+243"},
    // {"name":"Cote d'Ivoire","dial_code":"+225"},
    // {"name":"Falkland Islands (Malvinas)","dial_code":"+500"},
    // {"name":"Guernsey","dial_code":"+44"},
    // {"name":"Holy See (Vatican City State)","dial_code":"+379"},
    // {"name":"Hong Kong","dial_code":"+852"},
    // {"name":"Iran, Islamic Republic of","dial_code":"+98"},
    // {"name":"Isle of Man","dial_code":"+44"},
    // {"name":"Jersey","dial_code":"+44"},
    // {"name":"Korea, Democratic People's Republic of","dial_code":"+850"},
    // {"name":"Korea, Republic of","dial_code":"+82"},
    // {"name":"Lao People's Democratic Republic","dial_code":"+856"},
    // {"name":"Libyan Arab Jamahiriya","dial_code":"+218"},
    // {"name":"Macao","dial_code":"+853"},
    // {"name":"Macedonia, The Former Yugoslav Republic of","dial_code":"+389"},
    // {"name":"Micronesia, Federated States of","dial_code":"+691"},
    // {"name":"Moldova, Republic of","dial_code":"+373"},
    // {"name":"Mozambique","dial_code":"+258"},
    // {"name":"Palestinian Territory, Occupied","dial_code":"+970"},
    // {"name":"Pitcairn","dial_code":"+872"},
    // {"name":"Réunion","dial_code":"+262"},
    // {"name":"Russia","dial_code":"+7"},
    // {"name":"Saint Barthélemy","dial_code":"+590"},
    // {"name":"Saint Helena, Ascension and Tristan Da Cunha","dial_code":"+290"},
    // {"name":"Saint Kitts and Nevis","dial_code":"+1 869"},
    // {"name":"Saint Lucia","dial_code":"+1 758"},
    // {"name":"Saint Martin","dial_code":"+590"},
    // {"name":"Saint Pierre and Miquelon","dial_code":"+508"},
    // {"name":"Saint Vincent and the Grenadines","dial_code":"+1 784"},
    // {"name":"Sao Tome and Principe","dial_code":"+239"},
    // {"name":"Somalia","dial_code":"+252"},
    // {"name":"Svalbard and Jan Mayen","dial_code":"+47"},
    // {"name":"Syrian Arab Republic","dial_code":"+963"},
    // {"name":"Taiwan, Province of China","dial_code":"+886"},
    // {"name":"Tanzania, United Republic of","dial_code":"+255"},
    // {"name":"Timor-Leste","dial_code":"+670"},
    // {"name":"Venezuela, Bolivarian Republic of","dial_code":"+58"},
    // {"name":"Viet Nam","dial_code":"+84"},
    // {"name":"Virgin Islands, British","dial_code":"+1 284"},
    // {"name":"Virgin Islands, U.S.","dial_code":"+1 340"}];
    this.submitForm = this.formBuilder.group({
      // selectedOption: [this.countryCode[87].dial_code, Validators.required],
      mobileNumber: ['', [Validators.required, Validators.minLength(10)]],
    });
  }
  // onSelectionChanged(event) {
  // }
  onSubmit() {
    if (this.submitForm.valid) {
    this.router.navigate(['/contact-us', {mobileno: this.submitForm.value.mobileNumber}]);
    }
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  get f() { return this.submitForm.controls; }
}
