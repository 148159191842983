import { Component, OnInit, Inject } from '@angular/core';
import {Router,ActivatedRoute, } from '@angular/router';
import {LocationService} from '../../website/location/location.service';
import { LOCAL_STORAGE} from '@ng-toolkit/universal';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
declare const google: any;
declare const $: any;
declare const MarkerClusterer: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  constructor(private locationService: LocationService,
              private router: Router,private route:ActivatedRoute,
              @Inject(LOCAL_STORAGE) private localStorage: any,
              private scrollDispatcher: ScrollDispatcher) {
                this.scrollDispatcher.scrolled().subscribe((data: CdkScrollable) => {
                this.onWindowScroll(data.getElementRef().nativeElement);
              });
  }
  outlet: any;
  // locationView: any;
  // activeLinks: any;
  rewardName:any;
  rewardExpiry:any;
  location_details: any;
  map_data = [];
  markers: any;
  map: any;
  marker: any;
  markerCluster: any;
  locations = [];
  cityList:any = [];
  selectedCity: string = 'all';
  showView: any;
  completeLocationsList: any = [];
  copyCompleteLocationsList: any = [];
  maxItemsInTheList: number;
  location: any = [];
  searchedKeyword: string = '';
  filtered: boolean;
  id:any={};
  noDataFound: boolean = undefined;
  ngOnInit() {
    
    this.route.params.forEach((urlParams) => {
      this.id['campaign_id'] = urlParams['id'];
      this.id['chain_id'] = urlParams['chain_id'];
    });
    this.locationService.getCityList(this.id).subscribe((data: any) => {
      this.cityList = data.cities;
    });
    this.showView = 'grid';
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
    }
    this.filtered = false;
  }

  showPosition = (position) => {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;
    const params = { lat: lat , lon: lon};
    this.localStorage.setItem('userLocation', JSON.stringify(params));
    this.locationApi(params);
  }
  showError = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
      const params = {lat: 19.1364052, lon: 72.83304799999999};
      this.localStorage.setItem('userLocation', JSON.stringify(params));
      this.locationApi(params);
    }
  }

  onWindowScroll(data) {
    switch(true){
      // case this.router.url !== '/merchant/partner-locations':
      //   return;
      case data.nodeName === 'MAT-SIDENAV-CONTENT' && this.showView === 'map':
        return;
    }
    if(this.location.length !== this.maxItemsInTheList){
      let percentScrolled;
      switch(this.showView){
        case 'grid':
          let elem = document.getElementById('locationCardsContainer');
          percentScrolled = Math.round((data.scrollTop / elem.offsetHeight) * 100);
          break;
        case 'map':
          percentScrolled = Math.round((data.scrollTop / (data.scrollHeight - data.clientHeight))*100);
          break;
      }
      
      if(percentScrolled && Math.round(percentScrolled) > 90){
        let chunk = this.copyCompleteLocationsList.splice(0, 12);
        this.location = this.location.concat(chunk);
      }
    }
  }

  searchLocations(){
    if(this.searchedKeyword.length > 2){
        let results = this.completeLocationsList.filter((outlet) => {
        let keyword = this.searchedKeyword.toLowerCase();
        if(outlet.name.toLowerCase().includes(keyword) || outlet.location.toLowerCase().includes(keyword)){
          return outlet;
        }
      });
      this.filtered = true;
      if(results.length > 0){
        this.copyCompleteLocationsList = results;
        this.initializmap('gbmap',JSON.parse(this.localStorage.getItem('userLocation')), results);
        this.maxItemsInTheList = this.copyCompleteLocationsList.length;
        this.location = this.copyCompleteLocationsList.splice(0,12);
        this.noDataFound = false;
      }else{
        this.copyCompleteLocationsList = [];
        this.maxItemsInTheList = this.copyCompleteLocationsList.length;
        this.location = [];
        this.noDataFound = true;
      }
    }else if(this.filtered){
      this.copyCompleteLocationsList = JSON.parse(JSON.stringify(this.completeLocationsList));
      this.initializmap('gbmap',JSON.parse(this.localStorage.getItem('userLocation')), this.copyCompleteLocationsList);
      this.maxItemsInTheList = this.copyCompleteLocationsList.length;
      this.location = this.copyCompleteLocationsList.splice(0,12);
      this.filtered = false;
      this.noDataFound = false;
    }
  }

  getLocationsByFilter(event, filterType:string){
    let params = {};
    switch(true){
      case filterType === 'coordinates':
      case filterType === 'city' && event.value === 'all':
        params = JSON.parse(this.localStorage.getItem('userLocation'));
        this.selectedCity = 'all';
        break;
      case filterType === 'city':
        params = {
          city:event.value
        };
        this.selectedCity = event.value;
        break;
    }
    this.locationApi(params);
  }

  locationApi(params) {
    if(this.id.campaign_id){
      params['campaign_id']=this.id.campaign_id;
    } else if (this.id.chain_id){
      params['chain_id']=this.id.chain_id;
    }
    this.locationService.getLocationByBrand(params).subscribe((data: any) => {
    this.completeLocationsList =  data.locations_data;
    this.rewardName = data.reward_name;
    this.rewardExpiry = data.reward_expiry;
    this.initializmap('gbmap',params, data.locations_data);
    this.copyCompleteLocationsList = JSON.parse(JSON.stringify(this.completeLocationsList));
    this.maxItemsInTheList = this.copyCompleteLocationsList.length;
    this.location = this.copyCompleteLocationsList.splice(0, 12);
   } ,_error => {
    this.route.params.subscribe((params) =>{
      if(params.id){
        this.router.navigateByUrl('merchant/partner-locations');
      }
    });
  });
  }

  onclickLink(view:string):void {
    this.showView = view;
  }

  getOutletOnMap(outletInfo:any):void{
    let locData = [outletInfo];
    const params = {
      lat:outletInfo.lat,
      lon:outletInfo.lon
    };
    this.initializmap('gbmap', params, locData);
  }

  initializmap(gmap, params, mapListLocations) {
    // let map_center = new google.maps.LatLng(19.1365333,  72.8309986);
    let map_center = new google.maps.LatLng(params.lat,  params.lon);
    let map = new google.maps.Map(document.getElementById(gmap), {
        zoom: 20,
        center: map_center,
        mapTypeControlOptions: {
          mapTypeIds: []
        }
    });
    // this.locationService.getLocations(params).subscribe((location: any) => {
      // this.markers = location.locations_data;
      this.markers = mapListLocations;
      let marker_objects = [];
      for (let i = 0; i < this.markers.length; i++) {
        let infoToolTip   = `<a style='text-decoration:none' href='/merchant/details/${this.markers[i].slug}'>
          <div  style='width: 100%; height: 100%; overflow-x: hidden;display:flex;'>
            <div>
              <img src='${this.markers[i].logo}' onError="this.src='https://hashtagloyalty.s3-ap-southeast-1.amazonaws.com/WebsiteAssets/defaultBrandLogo.png';"  style='width: 30px;height:30px;'/>
            </div>
            <div style='margin:0px 10px;'>
              <div class='selected_merchant_name' style='color: #494a69; letter-spacing: -0.3px; text-align: left; line-height: 1.42;  font-size: 12px;font-weight: normal; font-stretch: normal; font-style: normal;'>
                ${this.markers[i].name}
              </div>
              <div style='font-size: 10px;font-weight: normal; font-style: normal;font-stretch: normal; line-height: 1.5;  color: #494a69;  text-align: left;'>
                ${this.markers[i].location}<br>
                ${this.markers[i].open_hours} to ${this.markers[i].closed_hours}<br>
                ${this.markers[i].category} | ${this.markers[i].sub_category}
              </div>
            </div>
          </div>
        </a>`;
        let infowindow = new google.maps.InfoWindow({
          content: infoToolTip
        });
        this.marker = new google.maps.Marker({
          position: new google.maps.LatLng(this.markers[i].lat, this.markers[i].lon),
          map: map,
          data: this.markers[i],
          title: this.markers[i].name,
          icon: 'https://hashtagloyalty.s3-ap-southeast-1.amazonaws.com/WebsiteAssets/image/page-1.svg'
        });
        this.marker.addListener('click',()=>{
          // const center  = new google.maps.LatLng(marker.data.lat, marker.data.lon);
          // infothis.window.open(map, this.marker);
        });
        marker_objects.push(this.marker);
        if(mapListLocations.length === 1){
          infowindow.open(map, this.marker);
        }
      }
      this.markerCluster = new MarkerClusterer(map, marker_objects,{imagePath: 'https://hashtagloyalty.s3-ap-southeast-1.amazonaws.com/WebsiteAssets/image/m'});
      function AutoCenter() {
          const bounds = new google.maps.LatLngBounds();
          $.each(marker_objects, function(index, marker) {
          bounds.extend(marker.position);
          });
          map.fitBounds(bounds);
      }
      AutoCenter();
    // });
  }

  onclickBusinessView(slug) {
    this.router.navigate(['merchant/details', slug]);
  }
}
