import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef , MAT_DIALOG_DATA} from '@angular/material';
import { DialogpopupComponent } from '../dialogpopup/dialogpopup.component';
@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css']
})
export class BenefitsComponent implements OnInit {
  navLinks: any;
  activeLink: any;
  salient_features: any;
  activeLinkbuild: any;
  features: any;
  screenshot: any;
  posList: any;
  navLinksBuild: any;
  buildScreenshot: any;
  data: any;
  constructor( public dialog: MatDialog) { }
  ngOnInit() {
    this.posList = [{imageName: 'hashtag-loyalty-integrated-with-posist',
                    alttitle: 'Integrated with POSist PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with POSist PoS system',
                    class: 'posist'},
                    {imageName: 'hashtag-loyalty-integrated-with-sanguine',
                    alttitle: 'Integrated with Sanguine PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with SanguinePoS system',
                    class: 'sanguine'},
                    {imageName: 'hashtag-loyalty-integrated-with-spectrum',
                    alttitle: 'Integrated with Spectrum PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with Spectrum PoS system',
                    class: 'spectrum'},
                    {imageName: 'hashtag-loyalty-integrated-shawman',
                    alttitle: 'Integrated with Shawman PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with Shawman PoS system',
                    class: 'shawman'},
                    {imageName: 'hashtag-loyalty-integrated-petpooja',
                    alttitle: 'Integrated with PetPooja PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with PetPooja PoS system',
                    class: 'petpooja'},
                    {imageName: 'hashtag-loyalty-integrated-semnox',
                    alttitle: 'Integrated with Semnox PoS system',
                    alttext: 'Logo for Hashtag Loyalty integration with Semnox PoS system',
                    class: 'semnox'}];
    this.salient_features = [{cardImage: 'simple-to-use-interface', cardTitle: 'Simple' ,
                            alttitle: 'Simple and easy-to-use',
                            alttext: 'Icon for Hashtag Loyalty is simple and easy-to-use',
                            cardContent: 'An easy-to-use interface that enables each member of a business to understand simply and use the platform to its maximum potential.'},
                            {cardImage: 'plug-play-implementation', cardTitle: 'Plug-Play',
                            alttitle: 'Plug and Play nature',
                            alttext: 'Icon for plug and play nature enables Hashtag Loyalty implementation in minutes',
                            cardContent: 'Implement Hashtag Loyalty in a matter of minutes compared to the days if not weeks taken by other solutions.'},
                            {cardImage: 'affordable-solution', cardTitle: 'Affordable',
                            alttitle: 'Affordable solution',
                            alttext: 'Icon for affordable and a result-driven solution',
                            cardContent: 'Hashtag Loyalty is a result-driven solution that will not burn a hole through your pocket.'}];
    this.features = [{cardImage: 'increase-customer-visit-frequency', cardTitle: 'Visit Frequency' ,
                    alttitle: 'Increase customer visit frequency',
                    alttext: 'Icon for Hashtag Loyalty increases customer visit frequency by 15%',
                    cardContent: 'Increase the average number of customer visits over a particular time by 15%'},
                    {cardImage: 'inrease-average-order-value', cardTitle: 'Average Order Value' ,
                    alttitle: 'Increase average order value of customers',
                    alttext: 'Icon for Hashtag Loyalty increases average customer spend per visit by 10%',
                    cardContent: 'Increase the average customer spend per visit by 10%'},
                    {cardImage: 'increase-business-revenue', cardTitle: 'Revenue',
                    alttitle: 'Increase your business revenue',
                    alttext: 'Icon for Hashtag Loyalty increases your business revenue by',
                    cardContent: 'Increase the revenue of your business by 25%'}];
    this.navLinks = ['LOYALTY', 'FEEDBACK', 'RESERVATIONS', 'CRM'];
    this.screenshot = [{imageName: 'customer-loyalty-overview', alttitle: 'Overview loyalty program performance',
                        alttext: 'Screenshot of Hashtag Loyalty overview of loyalty program performance'},
                       {imageName: 'customer-feedback-system', alttitle: 'Track and record customer feedback',
                       alttext: 'Screenshot of net promoter score via customer feeedback system'},
                       {imageName: 'customer-reservation-system', alttitle: 'Reservation system to manage customer bookings',
                        alttext: 'Screenshot of the customer reservation system to manage bookings and waitlist'},
                       {imageName: 'rfm-customer-segmentation', alttitle: 'Segment customers based on RFM traits',
                        alttext: 'Screenshot of RFM customer segementation and build customer journeys'}];
    this.navLinksBuild = ['ANALYTICS', 'LEADERBOARD', 'REPORTING'];
    this.buildScreenshot = [{content: 'Understand customer behaviour and preferences via actionable insights.',
                            imageName: 'customer-analytics',
                            alttitle: 'Understand customer behaviour and prefences',
                            alttext: 'Screenshot of understand customer behaviour via actionable insights and analytics',
                            class: 'analytics'},
                            {content: 'Overview and compare performance across multiple brand and franchise outlets',
                            imageName: 'outlet-performance-leaderboard',
                            alttitle: 'Leaderboard to compare multiple outlet performance',
                            alttext: 'Screenshot of leaderboard to compare the performance of multiple outlets of a chain brand',
                            class: 'leaderboard'},
                            {content: 'Make key business decisions based on regular performance reports.',
                            imageName: 'business-performance-reports',
                            alttitle: 'Track business performance via reports',
                            alttext: 'Screenshot of weekly business performance report',
                            class: 'reporting'}];
    this.data = {heading: 'Integrated Solution',
                content: 'Active integrations with major PoS - Point of Sale system providers from India enable you to implement a solution connected to your existing billing system.'};
    this.activeLink = 0;
    this.activeLinkbuild = 1;
  }
  selectedLink(i) {
    let element = document.getElementById('myimg');
    element.classList.add('transition');
    this.activeLink = i;
    setTimeout(() => {
      element.classList.remove('transition');
   }, 1000);
  }
  selectedLinkBuild(i) {
    let element = document.getElementById('myimg1');
    element.classList.add('transition');
    this.activeLinkbuild = i;
    setTimeout(() => {
      element.classList.remove('transition');
   }, 1000);
  }
  openDialog(view): void {
    const dialogRef = this.dialog.open(DialogpopupComponent, {
      // height: '400px',
      // width: '600px',
      data: {viewType: view}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}