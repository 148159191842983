import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {SpinTheWheelService } from './spin-the-wheel.service';
import {NavbarserviceService} from '../navbar/navbarservice.service';
@Component({
  selector: 'app-spin-the-wheel',
  templateUrl: './spin-the-wheel.component.html',
  styleUrls: ['./spin-the-wheel.component.css']
})
export class SpinTheWheelComponent implements OnInit {
  auth: any;
  game: any;
  rewardData: any;
  sliceIds: any;
  rewardSegments: any;
  constructor(private route: ActivatedRoute,
     private spinTheWheelService: SpinTheWheelService, private navbarService: NavbarserviceService) { }

  ngOnInit() {
    this.navbarService.hide();
    this.sliceIds = [];
    this.rewardSegments = [];
    // this.route.params.forEach((urlParams) => {
    //   this.auth = urlParams['auth'];
    // });
    // https://hashtagloyalty.com/gamification?merchant_id=1844&user_id=B5p7kWEmbiq5mMT5UUQ6&game_type=spin_the_wheel&template_type=basic
    this.auth = {merchant_id: '1844', user_id: 'B5p7kWEmbiq5mMT5UUQ6', game_type: 'spin_the_wheel', template_type: 'basic'};
    // this.spinTheWheelService.getUserInfo(this.auth).subscribe((data: any) => {
    //   this.game = data;
    //   console.log(this.game.game_data.data[0].attributes.background_color);
    //   this.rewardData = data.game_data.included;
    //   for (let i = 0; i < this.rewardData.length; i++) {
    //     let r = this.rewardData[i].attributes;
    //     this.sliceIds.push(r.id);
    //     this.rewardSegments.push({'strokeStyle' : 'rgba(255, 255, 255, .2)',
    //     'textFontFamily' : '"Lobster", cursive', 'textAligment' : 'inner',
    //      'textFontWeight' : 'bold', 'fillStyle' : r.background_color, 'text' : r.label});
    //   }
    // });
    console.log(this.game);
    console.log(this.sliceIds);
    console.log(this.rewardSegments);
  }

}
