import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-understand',
  templateUrl: './understand.component.html',
  styleUrls: ['./understand.component.css']
})
export class UnderstandComponent implements OnInit {
  navLinks: any;
  activeLink: any;
  imgActive: any;
  salient_features: any;
  activeCarousel: any;
  constructor() { }
  slides = [
    {img: '1'},
    {img: '2'},
    {img: '3'},
    {img: '4'},
    {img: '5'}
  ];
  slideConfig = {'slidesToShow': 3,
  'slidesToScroll': 3,
  // "dots": true,
  'infinite': true,
  'autoplay': true,
  // 'mobileFirst': true,
  // "accessibility": true,
  'centerMode': true,
  'centerPadding': '10px',
  // 'adaptiveHeight': true,
  'autoplaySpeed': 1000,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        autoplay: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    },
    {
      breakpoint: 480,
      settings: {
        autoplay: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    },
    {
      breakpoint: 320,
      settings: {
        autoplay: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      }
    }]
};
  afterChange(e) {
    this.activeCarousel =  e.currentSlide;
  }
  ngOnInit() {
    this.salient_features = [{cardImage: 'customer-rfm-segmentation',
                             cardTitle: 'Customer Segmentation',
                             alttitle: 'Segment customers based on RFM traits',
                             alttext: 'Icon for automatic customer segmentation based on RFM traits',
                             cardContent: 'Understand your customers via automatic segmentation as per their Recency, Frequency and Monetary characteristics.'},
                            {cardImage: 'customer-cohorts',
                             cardTitle: 'Custom Cohorts',
                             alttitle: 'Use tagging to create customer cohorts',
                             alttext: 'Icon for creation of customer cohorts using tagging',
                             cardContent: 'Create custom customer segments using customer tagging. Use these segments to conduct targeted customer engagement.'},
                            {cardImage: 'enterprise-grade-solution',
                             cardTitle: 'Enterprise Grade',
                             alttitle: 'Solution fit to cater enterpise level businesses',
                             alttext: 'Icon for solution fit to cater enterprise level businesses',
                             cardContent: 'Overview and compare performance across multiple brand and franchise outlets via a Brand Leaderboard and Reporting. Get notified of poor performing outlets that need attention.'},];
    this.navLinks = ['WEB', 'APP'];
    this.imgActive = [{imageName: 'merchant-business-dashboard-in-action',
                      alttitle: 'Track business performance across touchpoints',
                      alttext: 'Screenshot of the Hashtag Loyalty merchant business dashboard in action',
                      class: 'web' },
                      {imageName: 'merchant-mobile-app-in-action-1',
                      alttitle: 'Track business performance across touchpoints',
                      alttext: 'Screenshot of the Hashtag Loyalty merchant business mobile application in action',
                      class: 'app'}];
    this.activeLink = 0;
  }
  selectedLink(i) {
    let element = document.getElementById('myimg');
    element.classList.add('transition');
    this.activeLink = i;
    setTimeout(() => {
      element.classList.remove('transition');
   }, 1000);
  }
}