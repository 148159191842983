import { Component, OnInit, Inject,ChangeDetectorRef } from '@angular/core';
import { ProfileService } from './profile.service';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  user: any;
  visits:any;
  copyvisits: any;
  maxItemsInTheList: number;
  copyCompleteList: any;
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
                        private profileService: ProfileService,
                        private scrollDispatcher: ScrollDispatcher,
                        private changeDetection: ChangeDetectorRef) {
      this.scrollDispatcher.scrolled().subscribe((data: CdkScrollable) => {
      this.onWindowScroll(data.getElementRef().nativeElement);
    });
}
  CustomerTransection: any;
  ngOnInit() {
    this.profileService.getUserByAuth().subscribe((data: any) => {
      this.user = data.user;
      this.localStorage.setItem('user_details', JSON.stringify(data.user));
      this.copyCompleteList = JSON.parse(JSON.stringify(data.activities));
      this.maxItemsInTheList = this.copyCompleteList.length;
      this.visits = this.copyCompleteList.splice(0, 5);
      this.CustomerTransection = [{ title: 'Amount Spent', count: this.user.total_amount_spent },
      { title: 'Total Visits', count: this.user.total_points },
      { title: 'Rewards Redeemed', count: this.user.total_rewards },
      { title: 'Businesses Visited', count: this.user.merchant_visited }];
    });
  }

  onWindowScroll(data) {
    let elem = document.getElementById('innercontainer');
    if (this.visits && elem) {
      if (this.visits.length !== this.maxItemsInTheList) {
        let percentScrolled = Math.round((data.scrollTop / elem.offsetHeight) * 100);
        if (percentScrolled && Math.round(percentScrolled) > 90) {
          let chunk = this.copyCompleteList.splice(0, 5);
          this.visits = this.visits.concat(chunk);
          this.changeDetection.detectChanges();
        }
      }
    }
  }

}
