import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  features: any;
  constructor() { }
  ngOnInit() {
    this.features = [{cardImage: 'customer-satisfaction-score',
                    cardTitle: 'Customer Satisfaction',
                    alttitle: 'Track happiness via customer satisfaction score',
                    alttext: 'Icon for track happiness via customer satisfaction score',
                    cardContent: 'Track the happiness quotient of your  customers via a CSAT (Customer Satisfaction) score derived from the customer feedback you have received.'},
                    {cardImage: 'net-promoter-score',
                    cardTitle: 'Net Promoter Score',
                    alttitle: 'Track referral probability via a net promoter score',
                    alttext: 'Icon for track referral probability via a net promoter score',
                    cardContent: 'Track the likelihood of customers referring your business to others via a NPS - Net Promoter Score derived from the customer feedback you have received.'},
                    {cardImage: 'feedback-analytics',
                    cardTitle: 'Analytics',
                    alttitle: 'Get actionable insights on customer responses',
                    alttext: 'Icon for analytics and actionables insights on customer responses',
                    cardContent: 'Get actionable insights based on the customer feedback that you have received.'},
                    {cardImage: 'feedback-system-integrated-loyalty-program',
                    cardTitle: 'Feedback Integrated Loyalty',
                    alttitle: 'Complement feedback with a loyalty program',
                    alttext: 'Icon for feedback complemented with a loyalty program',
                    cardContent: 'Enrich your customer data by complementing feedback with an integrated loyalty program.'},
                    {cardImage: 'negative-customer-feedback-alert',
                    cardTitle: 'Negative Feedback Alerts',
                    alttitle: 'Get notified of each negative feedback',
                    alttext: 'Icon for negative feedback alerts',
                    cardContent: 'Get notified along with response details each time your business receives negative customer feedback.'},
                    {cardImage: 'restaurant-table-management',
                    cardTitle: 'Custom Feedback Forms',
                    alttitle: 'Manage restaurant tables in real-time',
                    alttext: 'Icon for real-time restaurant table management',
                    cardContent: 'Customize the questions and design the look of your customer feedback form to suit your business.'}];
  }
}