import { Component,OnInit,Inject,Optional,ViewChild,PLATFORM_ID  } from "@angular/core";
import { DialogpopupComponent } from "../dialogpopup/dialogpopup.component";
import { EdituserprofileService } from "./edituserprofile.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "../login/login.service";
import { ProfileService } from "../user-details/profile.service";
import { NavbarserviceService } from "../navbar/navbarservice.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import * as _moment from "moment";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { WindowRef } from "../../window-ref.service";
declare var hj;
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "L"
  },
  display: {
    dateInput: "Do MMM YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
import { FieldConfig } from "../input-components/field.interface";
import { DynamicFormComponent } from "../input-components/dynamic-form/dynamic-form.component";
import { isPlatformBrowser } from "@angular/common";
@Component({
  selector: "app-edituserprofile",
  templateUrl: "./edituserprofile.component.html",
  styleUrls: ["./edituserprofile.component.css"],
  providers: []
})
export class EdituserprofileComponent implements OnInit {
  @ViewChild(DynamicFormComponent) submitForm: DynamicFormComponent;
  maxDate = new Date();
  regConfig: FieldConfig[];
  submitted:boolean=false;
  auth: any;
  selectedImg: any;
  auth_token: any;
  updateddata: any;
  uploadedimg: any;
  img: any;
  header: any;
  is_disabled: boolean = false;
  userinfo: any = {};
  addMarginBottom: number = 0;
  fullWindowHeight: number;
  initialWindowHeight: number;
  windowListener: void;
  testBrowser:any;
  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    public dialog: MatDialog,
    private editService: EdituserprofileService,
    private navbarService: NavbarserviceService,
    private router: Router,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private winRef: WindowRef,
    public dialogRef: MatDialogRef<EdituserprofileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.testBrowser = isPlatformBrowser(platformId);
  }
  ngOnInit() {
    this.initialWindowHeight = this.winRef.nativeWindow.innerHeight;
    this.route.params.forEach(urlParams => {
      this.auth = urlParams["auth"];
    });
    this.auth_token = this.loginService.getToken();
    if (this.auth) {
      this.localStorage.setItem("auth_token", this.auth);
      this.auth_token = this.auth;
    }
    if (this.data && this.data.editProfile) {
      if (this.localStorage.getItem("user_details")) {
        this.regConfig= Object.assign([],this.data.profileForm);
        let user = JSON.parse(this.localStorage.getItem("user_details"));
        setTimeout(() => {
        this.setValueInForm(user);
      }, 1000);
      }
    } else {
      this.editService.getFormKey().subscribe((data:any)=>{
        this.regConfig = Object.assign([],data);
        this.profileService.getUserInfo().subscribe((data: any) => {
          this.setValueInForm(data.user);
        });
      });
    }
  }

  setValueInForm(data) {
    this.localStorage.setItem("user_name", data.fname);
    this.localStorage.setItem("user_details", JSON.stringify(data));
    this.userinfo = data;
    this.submitForm.form.patchValue({
      fname: this.userinfo.fname,
      lname: this.userinfo.lname,
      email: this.userinfo.email,
      mobile: this.userinfo.mobile,
      gender: this.userinfo.gender,
      communication:{
        is_email: this.userinfo.is_email,
        is_sms: this.userinfo.is_sms
      } 
    }); 

    if (this.userinfo.date_of_birth && this.userinfo.date_of_birth.length) {
      let dob: any = moment(this.userinfo.date_of_birth).format("YYYY/MM/DD");
      dob = dob.split("/");
      dob[0] = Math.abs(parseInt(dob[0]));
      dob = dob.join("/");
      this.submitForm.form.patchValue({
        date_of_birth: new Date(dob)
      });
    }
    if (this.userinfo.anniversary && this.userinfo.anniversary.length) {
      let anniversary: any = moment(this.userinfo.anniversary).format(
        "YYYY/MM/DD"
      );
      anniversary = anniversary.split("/");
      anniversary[0] = Math.abs(parseInt(anniversary[0]));
      anniversary = anniversary.join("/");
      this.submitForm.form.patchValue({
        anniversary: new Date(anniversary)
      });
    }
    this.selectedImg = this.userinfo.img
      ? this.userinfo.img
      : "https://hashtagloyalty.s3-ap-southeast-1.amazonaws.com/WebsiteAssets/user.svg";
  }
  
  getFile() {
    document.getElementById("uploadfile").click();
  }

  fileChangeEvent(fileInput) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      let reader = new FileReader();
      this.uploadedimg = fileInput.target.files[0].name;
      reader.onload = (e: any) => {
        this.selectedImg = e.target.result;
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  
  onSubmit(value: any) {
    if(!this.submitted){
      this.submitted= true;
      this.is_disabled = true;
      if (this.selectedImg === this.userinfo.img) {
        this.img = {};
      } else {
        this.img = { base64: this.selectedImg, file_name: this.uploadedimg };
      }
      let dob: any = value.date_of_birth ? moment(value.date_of_birth).format("YYYY/MM/DD") : '';
      if (dob && dob.length) {
        dob = dob.split("/");
        dob[0] = Math.abs(parseInt(dob[0]));
        dob = dob.join("/");
      }
      let anniversary: any = value.anniversary ? moment(value.anniversary).format("YYYY/MM/DD") : '';
      if (anniversary && anniversary.length) {
        anniversary = anniversary.split("/");
        anniversary[0] = Math.abs(parseInt(anniversary[0]));
        anniversary = anniversary.join("/");
      }
      if(!this.data){
        this.updateddata = {
          fname: value.fname ? value.fname : '',
          lname: value.lname ? value.lname : '',
          date_of_birth: dob,
          email: value.email ? value.email : '',
          gender: value.gender ? value.gender : '',
          email_notification: value.communication ? value.communication.is_email : this.userinfo.is_email,
          sms_notification: value.communication ? value.communication.is_sms : this.userinfo.is_email,
          authentication_token: this.auth_token,
          avatar: this.img,
          anniversary: anniversary
        };
      } else{
        this.updateddata = value;
        if(this.updateddata.date_of_birth){
          this.updateddata.date_of_birth = dob;
        } else if(this.updateddata.anniversary){
          this.updateddata.anniversary = anniversary;
        }
      }
      this.editService.editProfileUser(this.updateddata).subscribe((data: any) => {
          // hotjar virtual page view code
          // hj("vpv", "/edit/profile/success");

          if (this.data && this.data.editProfile) {
            this.dialogRef.close();
            if(this.data.fromfill !== 'feedback'){
              let popup = {
                type: `Congragulations!You have successfully enrolled for ${this.data.merchantName} loyalty program`,
                image: "thankyou",
                slug: this.data.slug
              };
              this.navbarService.openDialog(popup);
            }
          } else {
            let popup = {
              type: "Great! You've updated your profile successfully!",
              image: "thankyou"
            };
            this.navbarService.openDialog(popup);
            
            setTimeout(() => {
              this.router.navigate(["/profile"]);
              this.submitted= false;
            }, 2500);
          }
        },
        error => {
          if (this.data && this.data.editProfile) {
            this.is_disabled = false;
          } else {
            this.is_disabled = true;
          }
          let popup = {
            type:
              "Oops! Something went wrong, please try again after some time.",
            image: "error"
          };
          this.navbarService.openDialog(popup);
          this.submitted= false;
        }
      );
      }
  }

  openDialog(view): void {
    // hotjar virtual page view code
    // hj("vpv", "/edit/profile/merge");
    const dialogRef = this.dialog.open(DialogpopupComponent, {
      height: "auto",
      width: "600px",
      data: { viewType: view }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  closeEditProfile() {
    this.dialogRef.close();
    if(this.data.fromfill !== 'feedback'){
    this.router.navigate([`/merchant/details/${this.data.slug}`], {
      replaceUrl: true
    });
  }
  }
  //function to add margin on focus in mobile device
  focusInputEditProfile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        this.winRef.nativeWindow.navigator.userAgent
      )
    ) {
      setTimeout(() => {
        if (this.data && this.data.editProfile) {
          this.addMarginBottom =
            this.winRef.nativeWindow.screen.availHeight -
            Math.round(this.winRef.nativeWindow.visualViewport.height);

          this.winRef.nativeWindow.addEventListener("resize", () => {
            this.listenWindow.call(this, this.initialWindowHeight);
          });
        }
      }, 200);
    }
  }

  listenWindow(initialWindowHeight) {
    if (this.winRef.nativeWindow.innerHeight == initialWindowHeight) {
      this.addMarginBottom = 0;
      this.winRef.nativeWindow.removeEventListener("resize", this.listenWindow);
    }
  }
  //function to remove margin on focus out in mobile device
  focusOutInputEditProfile() {
    this.addMarginBottom = 0;
  }
}
