import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private url: string = environment.WEB_API;
  constructor(public http: HttpClient) { }
  headertype = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  getFeedbackQuestion(params) {
    return this.http.get(this.url + '/web/api/v2/merchant/feedback', { headers: this.headertype, params: params });
  }
  submitFeedbackAnswer(params) {
    return this.http.post(this.url + '/web/api/v2/merchant/submit_feedback', params);
  }
}
