import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-footer',
  templateUrl: './report-footer.component.html',
  styleUrls: ['./report-footer.component.css']
})
export class ReportFooterComponent implements OnInit {
  @Input() footerdata: any;
  multimedia: any;
  constructor() { }

  ngOnInit() {
    this.multimedia = [{link: 'http://www.facebook.com/HashtagLoyalty', imgname: 'facebook-black', classname: 'fb'},
                       {link: 'http://www.twitter.com/HashtagLoyalty', imgname: 'twitter-black', classname: 'tweet'},
                       {link: 'http://www.instagram.com/HashtagLoyalty', imgname: 'instagram-black', classname: 'insta'},
                       {link: 'https://in.linkedin.com/company/hashtag-loyalty', imgname: 'linkedin-black', classname: 'linked'}];
  }

}
