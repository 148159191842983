import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value:any,arg:any) {
    if(arg === 'url'){
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    } else if (arg === 'html'){
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
