import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LoginService } from "../login/login.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-thank-you-popup",
  templateUrl: "./thank-you-popup.component.html",
  styleUrls: ["./thank-you-popup.component.css"]
})
export class ThankYouPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ThankYouPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loginService: LoginService,
    private router: Router
  ) {}

  ngOnInit() {}
  onNoClick(parameter): void {
    if (parameter === "ok") {
      this.loginService.logout();
    } else if (parameter == "cancel" && this.data.slug) {
      setTimeout(() => {
        this.router.navigate([`/merchant/details/${this.data.slug}`], {
          replaceUrl: true
        });
      }, 1000);
    }
    this.dialogRef.close();
  }
}
