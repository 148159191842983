import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../website/login/login.service';
import {Router} from '@angular/router';
import { timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { Pipe, PipeTransform, Inject } from '@angular/core';
import {NavbarserviceService} from '../navbar/navbarservice.service';
import {environment} from '../../../environments/environment';
import { WINDOW } from '@ng-toolkit/universal';
// declare var hj;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  password: any;
  countDown: any;
  submitted: any;
  submittedotp: any;
  counter = 120;
  private dashboardLogin = environment.LOGIN_DASHBOARD_API;
  constructor(
    @Inject(WINDOW) private window: Window,
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private navbarService: NavbarserviceService
  ) {}
  otpView: any;
  mobileView: any;
  loginMobile: FormGroup;
  loginOtp: FormGroup;
  is_invalid: any;

  ngOnInit() {
    this.mobileView = true;
    this.loginMobile = this.fb.group({
      mobileNumber: ["", [Validators.required, Validators.pattern("^\\d+$")]]
    });
    this.loginOtp = this.fb.group({
      otp: ["", [Validators.required, Validators.pattern("[1-9][0-9]{3}")]]
    });
  }
  get f() {
    return this.loginMobile.controls;
  }
  get fo() {
    return this.loginOtp.controls;
  }

  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    const mobile_number = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(mobile_number)) {
      event.preventDefault();
    }
    if (event.keyCode === 32) {
      mobile_number.replace(/\D/g, "");
      event.preventDefault();
    }
  }

  onChange() {
    this.is_invalid = false;
  }

  loginmobile(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.loginMobile.valid) {
      this.otpView = true;
      this.mobileView = false;
      this.resentotpFun();
    }
  }

  loginotp(event) {
    event.preventDefault();
    this.submittedotp = true;
    if (this.loginOtp.valid) {
      // tslint:disable-next-line:max-line-length
        const credential = {login: this.loginMobile.value.mobileNumber,  password: btoa(this.password), otp_status: 'true', otp: this.loginOtp.value.otp};
        this.loginService.loginUser(credential).subscribe((data: any) => {
          // hj('vpv', '/login/otp/login-success');
          this.navbarService.popup('Awesome! You\'ve logged in successfully!' , '', 'popup-success');
          this.router.navigate(['/profile']);
        } , error => {
          if (error.status === 401) {
            this.is_invalid = true;
          } else {
          let popup = {type: 'Oops! Something went wrong, please try again after some time.', image: 'error'};
          this.navbarService.openDialog(popup);
          }
        });
      
    }
  }

  resentotpFun() {
    this.countDown = undefined;
    this.counter = 20;
    this.counterFun();
    this.checkType();
    this.loginOtp.reset();
    this.submittedotp = false;
  }

  counterFun() {
    this.countDown = timer(0, 1000).pipe(
      take(this.counter),
      map(() => --this.counter)
    );
  }

  checkType() {
      this.loginService.getOTPUser(this.loginMobile.value.mobileNumber).subscribe((message: any) => {
        // hotjar virtual page view code
        // hj('vpv', '/login/otp');
      });
  }
}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
