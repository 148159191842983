import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EBillService {
  private url: string = environment.WEB_API;
  private invoceURL:string = environment.invoice_API;
  constructor(public http: HttpClient) { }
  header = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  getBillData(slug:string,bill_number:any) {
    return this.http.get(this.url + '/web/api/v2/'+slug+'/ebill/'+bill_number , { headers:this.header});
  }
  getInvoice(slug:string,bill_number){
    return this.http.get(this.invoceURL+'/contactless_dining/api/v4/merchants/'+slug+'/visits/'+bill_number+'/invoice.pdf',{ headers:this.header, responseType: 'blob'});
  }

}
