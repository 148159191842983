import { Component, OnInit } from '@angular/core';
import {LoginService} from '../login/login.service';
import {NavbarserviceService} from '../navbar/navbarservice.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  multimedia: any;
  featureLink: any;
  companyLink: any;
  ConsumerLink: any;
  auth_token: any;
  constructor(public loginService: LoginService, private navbarService: NavbarserviceService) { }
  ngOnInit() {
    this.multimedia = [{link: 'http://www.facebook.com/HashtagLoyalty', imgname: 'facebook-white', classname: 'facebook'},
                       {link: 'http://www.twitter.com/HashtagLoyalty', imgname: 'twitter-white', classname: 'twitter'},
                       {link: 'http://www.instagram.com/HashtagLoyalty', imgname: 'instagram-white', classname: 'instagram'},
                       {link: 'https://in.linkedin.com/company/hashtag-loyalty', imgname: 'linkedin-white', classname: 'linkedin'}];

    this.featureLink = [{path: '/loyalty', link: 'Loyalty'},
                        {path: '/feedback', link: 'Feedback'},
                        {path: '/reservation', link: 'Reservations'},
                        {path: '/understand', link: 'Understand'},
                        {path: '/engage', link: 'Engage'},
                        {path: '/benefits', link: 'Benefits'}];

    this.companyLink = [
                        {path: '/about-us', link: 'About us'},
                        // {path: '/team', link: 'Team'},
                        // {path: '/blog', link: 'Blog'},
                        // {path: '/hiring', link: 'Hiring'},
                        {path: '/contact-us', link: 'Contact us'}];

    this.ConsumerLink = [
                        {path: '/merchant/partner-locations', link: 'Locations'}];
  }

  logout() {
    let popup = {logout: 'Are you sure!', logoutmsg: 'Logout'};
    this.navbarService.openDialog(popup);
  }
}
