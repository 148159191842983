import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-stamp-reward',
  templateUrl: './stamp-reward.component.html',
  styleUrls: ['./stamp-reward.component.css','../merchant-details.component.css']
})
export class StampRewardComponent implements OnInit {
  @Input() stampData:any;
  possibleRewardsArray:number[];
  totalStampsArray:any=[]
  currentEarnedStamp: any = 1;
  earnedStamps: any[][] = [];
  earnedReward: any[] = [];
  
  constructor() { }

  ngOnInit() {
    if (Object.keys(this.stampData).length !== 0) {
      let noOfContainers = this.stampData.earned_stamps / this.stampData.total_stamps;
      this.possibleRewardsArray = [
        ...Array(Math.ceil(noOfContainers ? noOfContainers : 1)).keys()
      ];

      this.totalStampsArray = Array(
        this.stampData.total_stamps
      ).fill(1);

      for (let i = 0; i < this.possibleRewardsArray.length; i++) {
        this.earnedStamps[i] = [];

        for (let j = 0; j < this.totalStampsArray.length; j++) {
          this.earnedStamps[i][j] = {};
          this.earnedStamps[i][j].earned =
            this.currentEarnedStamp <=
            this.stampData.earned_stamps;
          this.currentEarnedStamp++;
        }
      }
      this.currentEarnedStamp = 1;
      let earnedReward = false;
      for (let i = 0; i < this.possibleRewardsArray.length; i++) {
        for (let j = 0; j < this.totalStampsArray.length; j++) {
          this.earnedStamps[i][j].date =
            this.stampData.earned_stamp_dates[
              this.currentEarnedStamp - 1
            ] || "";
          if (this.earnedStamps[i][j].earned) {
            earnedReward = true;
          } else {
            earnedReward = false;
          }
          this.currentEarnedStamp++;
        }
        this.earnedReward[i] = earnedReward;
        earnedReward = false;
      }
    }
  }
}
