import { Injectable, Inject } from "@angular/core";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { LOCAL_STORAGE } from "@ng-toolkit/universal";
import { Observable } from "rxjs";
import { LoginService } from "../login/login.service";
// import {headers} from '../../../global';
@Injectable({
  providedIn: "root"
})
export class ReferralService {
  private url: string = environment.WEB_API;
  constructor(public http: HttpClient, private loginService: LoginService) {}

  //API service method for referral activation status
  getReferralValidity(params, headers): Observable<any> {
    return this.http.get(this.url + "/web/api/v2/referrals/rewards", {
      headers,
      params
    });
  }

  //API service method to get referral data
  getReferralData(params): Observable<any> {
    const auth_token = this.loginService.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: auth_token
    });

    return this.http.get(this.url + "/web/api/v2/referrals/user_refer_link", {
      headers,
      params
    });
  }

  //API service method to change the count of times a paltform is clicked
  changeSharedPlatformCount(params) {
    const auth_token = this.loginService.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: auth_token
    });

    return this.http.post(
      this.url + "/web/api/v2/referrals/share_and_click_count",
      params,
      { headers }
    );
  }

  //API service method to call API to claim the reward;
  claimReward(params) {
    const auth_token = this.loginService.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: auth_token
    });
    return this.http.post(
      this.url + "/web/api/v2/referrals/claim_reward",
      params,
      {
        headers
      }
    );
  }
}
