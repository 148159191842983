import { Component, OnInit ,Inject} from '@angular/core';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { NavbarserviceService } from "../../navbar/navbarservice.service";
@Component({
  selector: 'app-reward-view',
  templateUrl: './reward-view.component.html',
  styleUrls: ['./reward-view.component.css','../merchant-details.component.css']
})
export class RewardViewComponent implements OnInit {

  constructor( @Inject(LOCAL_STORAGE) private localStorage: any,private navbarService: NavbarserviceService) { }
  normalReward:any;
  specialReward:any;
  stampData:any;
  merchantData:any;
  ngOnInit() {
    this.navbarService.hide();
    this.normalReward = JSON.parse(this.localStorage.getItem('normal_reward'));
    this.specialReward = JSON.parse(this.localStorage.getItem('special_reward'));
    this.stampData = JSON.parse(this.localStorage.getItem('stamp_reward'));
    this.merchantData = JSON.parse(this.localStorage.getItem('merchantData'));
  }
 goBack(){
  window.history.back();
 }
}
