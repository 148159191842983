import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-generic-report-row',
  templateUrl: './generic-report-row.component.html',
  styleUrls: ['./generic-report-row.component.css']
})
export class GenericReportRowComponent implements OnInit {
@Input() businessMatrics: any;
  constructor() { }

  ngOnInit() {
  }

}
