import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import {environment} from '../../../environments/environment';
// import {headertype} from '../../../global';
@Injectable({
  providedIn: 'root'
})
export class SpinTheWheelService {
  private url: string = environment.WEB_API;
  constructor(private http: HttpClient) { }
  // getUserInfo(authToken) {
  //   return this.http.get(this.url + '/web/api/v1/games/setup_game', {headers: headertype, params: authToken });
  // }
}
