import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-generic-report-card',
  templateUrl: './generic-report-card.component.html',
  styleUrls: ['./generic-report-card.component.css']
})
export class GenericReportCardComponent implements OnInit {
  @Input() displayedColumns: any;
  @Input() tableData: any;
  @Input() showDownload: any;
  @Input() showRecords: any;
  @Input() sectionName: string;
  visible: any;
  public csvFileName = `report.csv`;
  showdata: any;
  constructor() { }

ngOnInit() {
  switch (this.showRecords) {
    case 5:
    this.showdata = this.tableData.slice(0, 5);
      break;
    case 10:
    this.showdata = this.tableData.slice(0, 10);
      break;
    default:
    this.showdata = this.tableData;
  }
}
downloadCSVFile() {
  const items = this.tableData;
  const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
  let header = Object.keys(items[0]);
  let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  header = header.map((field) => {
    field = field.replace('_', ' ');
    if (field.toLowerCase() === 'details') {
      field = 'name';
    }
    return field;
  });
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  let exportedFilename = this.sectionName + '_report.csv'; // create downloadable link
  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

}
