import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-popup-taxes',
  templateUrl: './popup-taxes.component.html',
  styleUrls: ['./popup-taxes.component.css']
})
export class PopupTaxesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PopupTaxesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }
    taxTotal:number;
  ngOnInit() {
    if(this.data){
      let sum = 0;
      this.data.forEach((v:any) => {
        sum += v.value;
      });
      this.taxTotal = sum;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
