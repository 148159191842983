import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { LoginService } from "../login/login.service";
@Injectable({
  providedIn: "root"
})
export class EdituserprofileService {
  private url: string = environment.WEB_API;
  constructor(public http: HttpClient, private loginService: LoginService) {}
  headers = new HttpHeaders({
    "Content-Type": "application/json",
    Authorization: this.loginService.getToken() || ""
  });
  editProfileUser(body) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.loginService.getToken()
    });

    return this.http.post(this.url + "/web/api/v2/user/edit", body, {
      headers: this.headers
    });
  }
  mergeAccount(body) {
    return this.http.post(this.url + "/web/api/v2/user/merge-account", body, {
      headers: this.headers
    });
  }
  mergeAccountverifyOtp(body) {
    return this.http.post(this.url + "/web/api/v2/user/verify-merge", body, {
      headers: this.headers
    });
  }
  getFormKey(){
    return this.http.get(this.url + "/web/api/v2/merchant/user_fields",{ headers:this.headers})
  }
  getFormKeyById(id){
    return this.http.get(this.url + "/web/api/v2/merchant/user_fields?id=" +id,{ headers:this.headers})
  }
}
