import { Component, OnInit } from '@angular/core';
import {NavbarserviceService} from '../navbar/navbarservice.service';
import {ReportService} from '../merchant-weekly-report/report.service';
import {Router, ActivatedRoute} from '@angular/router';
import { Title} from '@angular/platform-browser';
@Component({
  selector: 'app-merchant-weekly-report',
  templateUrl: './merchant-weekly-report.component.html',
  styleUrls: ['./merchant-weekly-report.component.css']
})
export class MerchantWeeklyReportComponent implements OnInit {

  displayedColumns: string[] = ['reward_name', 'redemptions'];
  displaytitle: string[] = ['tier', 'visits' , 'sales'];
  displayloyalcustomer: string[] = ['details', 'visits', 'sales'];
  displayBirthweek: string[] = ['details', 'date'];
  displaycustomtag: string[] = ['tags', 'total_customers', 'visits_count'];
  displayAutoengage: string[] = ['segment_name', 'customers_visited', 'revenue_generated'];
  leaderboardloyalty: string[] = ['outlet_name', 'total_visits', 'new_users'];
  leaderboardfeedback: string[] = ['details', 'csat', 'nps'];
  leaderboardreservationcol: string[] = ['details', 'created', 'completed'];
  merchantReport: any;
  leaderboardreservation: any;
  leaderboardfeedbackdata: any;
  auth: any;
  report_date: any;
  period: any;
  constructor(public nav: NavbarserviceService, private report: ReportService, private route: ActivatedRoute, 
    private router: Router, private titleService: Title) { }
  ngOnInit() {
    this.route.params.forEach((urlParams) => {
      this.auth = urlParams['auth'];
      this.report_date = urlParams['report_date'];
      this.period = urlParams['period'];
      this.titleService.setTitle(this.period + 'Performance Report | Hashtag Loyalty');
    });
    const params = {report_date: this.report_date, period: this.period};
    if (this.auth) {
    this.report.getReport(this.auth, params).subscribe((reportData: any) => {
      this.merchantReport = reportData.main_data;
    });
  }
    this.nav.hide();
  }

}
