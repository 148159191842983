import { Component, OnInit } from '@angular/core';
import { NavbarserviceService } from '../navbar/navbarservice.service';
import { ReportService } from '../merchant-weekly-report/report.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-merchant-campaign-report',
  templateUrl: './merchant-campaign-report.component.html',
  styleUrls: ['./merchant-campaign-report.component.css']
})
export class MerchantCampaignReportComponent implements OnInit {

  constructor(public nav: NavbarserviceService, public campaignReportService: ReportService,private route: ActivatedRoute,) { }
  displayedColumnsTier: string[];
  showDisplayedColumnsTier:any;
  outletLeaderboardColumns: string[]; 
  showOutletLeaderboardColumns:any; 
  cityLeaderboardColumns: string[];
  showCityLeaderboardColumns:any;
  itemPurchaseColumns: string[] = ['item_name', 'count'];
  showitemPurchaseColumns = { 'item_name': 'item Name', 'count': 'Count' };
  tableDataitemPurchase: any;
  itemPurchaseTotal = {};
  campaignDetails: any;
  rewardDetails: any;
  tableDataLoyalty: any;
  tableDataoutletLeaderBoard: any
  tableDataCityLeaderBoard: any;
  smsDetails: any;
  emailDetails: any;
  overallCampaign: any;
  footerData: any;
  campaignInfo:any;
  merchantAuthToken:any;
  campaignReportId:any;
  ngOnInit() {
    this.nav.hide();
    this.route.params.forEach((urlParams) => {
      this.merchantAuthToken = urlParams['auth_token'];
      this.campaignReportId = urlParams['campaignId'];
    });
    
    const params = { campaign_id: this.campaignReportId };
    this.campaignReportService.getCampaignReport(this.merchantAuthToken, params).subscribe((campaignData: any) => {
      if(campaignData.campaign_type === 'info' || campaignData.campaign_type === 'pre-reward'){
        // outlet leaderboard
        this.outletLeaderboardColumns = ['outlet_name', 'customers', 'revenue'];
        this.showOutletLeaderboardColumns = { 'outlet_name': 'Outlet Name', 'customers': 'Customers', 'revenue': 'Revenue' };
        // city leaderboard
        this.cityLeaderboardColumns = ['city_name', 'customers', 'revenue'];
        this.showCityLeaderboardColumns = { 'city_name': 'City Name', 'customers': 'Customers', 'revenue': 'Revenue' };
        // loyalty segment
        this.displayedColumnsTier = ['tier', 'visits', 'revenue'];
        this.showDisplayedColumnsTier = { 'tier': 'Tier', 'visits': 'Visits', 'revenue': 'Revenue' };
        if(campaignData.campaign_type === 'pre-reward'){
          this.showOutletLeaderboardColumns.customers = 'Customers*';
          this.showCityLeaderboardColumns.customers = 'Customers*';
        }
        this.tableDataoutletLeaderBoard = campaignData.merchant_leaderboard;
        this.tableDataLoyalty = campaignData.loyalty_leaderboard;
        this.tableDataCityLeaderBoard = campaignData.city_leaderboard;
      } else {
        // outlet leaderboard
        if(campaignData.merchant_leaderboard){
        this.outletLeaderboardColumns = ['outlet_name', 'customers', 'redeemed'];
        this.showOutletLeaderboardColumns = { 'outlet_name': 'Outlet Name', 'customers': 'Customers*', 'redeemed': 'Redemptions' };
        this.tableDataoutletLeaderBoard = JSON.parse(JSON.stringify(campaignData.merchant_leaderboard).split('"revenue":').join('"revenue_":'));
        }
        // city leaderboard
        if(campaignData.city_leaderboard){
        this.cityLeaderboardColumns = ['city_name', 'customers', 'redeemed'];
        this.showCityLeaderboardColumns = { 'city_name': 'City Name', 'customers': 'Customers*', 'redeemed': 'Redemptions' };
        this.tableDataCityLeaderBoard = JSON.parse(JSON.stringify(campaignData.city_leaderboard).split('"revenue":').join('"revenue_":'));
        }
        // loyalty segment
        if(campaignData.loyalty_leaderboard){
        this.displayedColumnsTier = ['tier', 'visits', 'redeemed'];
        this.showDisplayedColumnsTier = { 'tier': 'Tier', 'visits': 'Visits', 'redeemed': 'Redemptions' };
        this.tableDataLoyalty = JSON.parse(JSON.stringify(campaignData.loyalty_leaderboard).split('"revenue":').join('"revenue_":'));
        }
      }
      this.campaignInfo = campaignData;
      this.campaignDetails = campaignData.campaigns_details;
      this.smsDetails = campaignData.sms_details;
      this.emailDetails = campaignData.email_details;
      this.tableDataitemPurchase = campaignData.item_leaderboard;
      this.overallCampaign = campaignData.overall_campaign_stats;
      if(campaignData.reward_details && !campaignData.reward_details[1].text){
        campaignData.reward_details[1].text="-"
      }
      this.rewardDetails = campaignData.reward_details;
      this.itemPurchaseTotal = { name: 'Total Items Purchased', value: campaignData.total_items_count};
      this.footerData = campaignData.admin_data;
    });
  }
}
