import { Component, OnInit } from '@angular/core';
import { NavbarserviceService} from '../navbar/navbarservice.service'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  features: any;
  i = 0;
  text: string;
  speed = 50;
  constructor(private nav : NavbarserviceService) { }
  ngOnInit() {
    this.nav.visible = true;
    // this.text = 'Grow your business - one loyal customer at a time!';
    this.features = [{cardImage: 'capture-customer-data', cardTitle: 'capture', alttitle: 'Capture first-party customer data with Hashtag Loyalty',
    alttext: 'Icon for capture customer data via loyalty program, feedback or reservation system.',
    cardContent:'first-party customer data via a touchpoint - <a href="/loyalty" class="hyperlink">loyalty program</a>, <a href="/feedback" class="hyperlink">feedback</a> or <a href="reservation" class="hyperlink">reservation system</a> - that adds value to your business and enhances customer experience.'},
    {cardImage: 'understand-customer-behaviour', cardTitle: 'understand', alttitle: 'Understand customer behaviour with Hashtag Loyalty', alttext: 'Icon for understand customers via performance reporting, analytics, segmentation and actionable insights.',
    cardContent: 'customer behaviour and preferences through performance reports, actionable insights and in-depth customer segmentation that help you make key business decisions.  '},
    {cardImage: 'customer-engagement', cardTitle: 'engage' , alttitle: 'Engage customers with Hashtag Loyalty', alttext: 'Icon for engage customers via automated, targeted and personalised campaigns.',
    cardContent: 'with customers at scale in an automated, targeted and personalized manner based on their activity. Track engagement performance and revenue generated in real-time.'}];
    // this.typewritter(this.text, this.i);
  }
  // typewritter(text, i) {
  //     if (i < text.length) {
  //       document.getElementById('section1').innerHTML += text.charAt(i);
  //       i++;
  //       setTimeout(() => {
  //         this.typewritter(text, i);
  //       }, 50);
  //     }
  //   }
}
