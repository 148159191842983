import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-generic-carousal-card',
  templateUrl: './generic-carousal-card.component.html',
  styleUrls: ['./generic-carousal-card.component.css']
})
export class GenericCarousalCardComponent implements OnInit {
@Input() indexNo: any;
@Input() activeIndex: any;
@Input() data: any;
  constructor() { }

  ngOnInit() {
  }

}
