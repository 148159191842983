import {
  BrowserModule,
  BrowserTransferStateModule
} from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
// website component
import { HomeComponent } from "./website/home/home.component";
import { NavbarComponent } from "./website/navbar/navbar.component";
// angular flex layout
import { FlexLayoutModule } from "@angular/flex-layout";
import { SlickCarouselModule } from "ngx-slick-carousel";
// scrolling
import { PlatformModule } from "@angular/cdk/platform";
// angular material package
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
// angular material module
import {
  MatToolbarModule,
  MatTabsModule,
  MatSidenavModule,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatExpansionModule
} from "@angular/material";
import { GenericFeatureCardComponent } from "./website/generic-feature-card/generic-feature-card.component";
import { GenericCarousalCardComponent } from "./website/generic-carousal-card/generic-carousal-card.component";
import { FooterComponent } from "./website/footer/footer.component";
import { GetdemoComponent } from "./website/getdemo/getdemo.component";
import { ReservationComponent } from "./website/reservation/reservation.component";
import { FeedbackComponent } from "./website/feedback/feedback.component";
import { LoyaltyComponent } from "./website/loyalty/loyalty.component";
import { UnderstandComponent } from "./website/understand/understand.component";
import { EngageComponent } from "./website/engage/engage.component";
import { BenefitsComponent } from "./website/benefits/benefits.component";
import { ContactUsComponent } from "./website/contact-us/contact-us.component";
import { HiringComponent } from "./website/hiring/hiring.component";
import { JobDetailComponent } from "./website/job-detail/job-detail.component";
import { LoginComponent } from "./website/login/login.component";
import { MerchantWeeklyReportComponent } from "./website/merchant-weekly-report/merchant-weekly-report.component";
import { IntegratedSolutionComponent } from "./website/integrated-solution/integrated-solution.component";
// import services
import { NavbarserviceService } from "./website/navbar/navbarservice.service";
import { ReportService } from "./website/merchant-weekly-report/report.service";
//import component
import { GenericReportCardComponent } from "./website/generic-report-card/generic-report-card.component";
import { ReportFooterComponent } from "./website/report-footer/report-footer.component";
import { GenericReportRowComponent } from "./website/generic-report-row/generic-report-row.component";
import { MerchantCampaignReportComponent } from "./website/merchant-campaign-report/merchant-campaign-report.component";
import { UserDetailsComponent } from "./website/user-details/user-details.component";
import { WebFeedbackComponent } from "./website/web-feedback/web-feedback.component";
import { AboutUsComponent } from "./website/about-us/about-us.component";
import { MerchantDetailsComponent } from "./website/merchant-details/merchant-details.component";
import { DialogpopupComponent } from "./website/dialogpopup/dialogpopup.component";
import { LocationComponent } from "./website/location/location.component";
import { CustomerSpecksComponent } from "./website/customer-specks/customer-specks.component";
import { TermsPrivacyComponent } from "./website/terms-privacy/terms-privacy.component";
import { TeamComponent } from "./website/team/team.component";
import { SafePipe } from "./website/safe-pipe.pipe";
import { FormatTimePipe } from "./website/login/login.component";
import { EdituserprofileComponent } from "./website/edituserprofile/edituserprofile.component";
import { ThankYouPopupComponent } from "./website/thank-you-popup/thank-you-popup.component";
import { UrlShortnerComponent } from "./website/url-shortner/url-shortner.component";
import { SpinTheWheelComponent } from "./website/spin-the-wheel/spin-the-wheel.component";


import { InputComponent } from './website/input-components/input/input.component';
import { SubmitButtonComponent } from './website/input-components/submit-button/submit-button.component';
import { SelectComponent } from './website/input-components/select/select.component';
import { DateComponent } from './website/input-components/date/date.component';
import { DynamicFormComponent } from './website/input-components/dynamic-form/dynamic-form.component';
import { DynamicFieldDirective } from './website/input-components/dynamic-field/dynamic-field.directive';

import { AppModule } from "./app.module";

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // flex layout
    FlexLayoutModule,
    SlickCarouselModule,
    // angular material module
    MatToolbarModule,
    MatTabsModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    PlatformModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatExpansionModule,
    AppModule,
    BrowserTransferStateModule
  ],
  // export: [MatSidenavModule],
  providers: [NavbarserviceService, ReportService, MatDatepickerModule],
  bootstrap: [AppComponent],
  entryComponents: [DialogpopupComponent,
    ThankYouPopupComponent,
    ThankYouPopupComponent,
   
    InputComponent,
    SelectComponent,
    DateComponent,
    SubmitButtonComponent,
    ]
})
export class AppBrowserModule {}
