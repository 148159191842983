import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-reward-row',
  templateUrl: './reward-row.component.html',
  styleUrls: ['./reward-row.component.css','../merchant-details.component.css']
})
export class RewardRowComponent implements OnInit {

  constructor() { }
  @Input() normalReward:any;
  @Input() rewardStamp:any;
  @Input() specialReward:any;
  ngOnInit() {
    if(this.specialReward){
      this.normalReward.forEach(element => {
        if(element.expiry_date){
          let date = new Date();
          date.setDate( date.getDate() + element.expiry_date );
          element.expiry_date = date;
        }
      });
    }
  }

}
