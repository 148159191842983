import { Component, OnInit } from "@angular/core";
import { NavbarserviceService } from "../../navbar/navbarservice.service";
import { ClaimRewardPopupComponent } from "./claim-reward-popup/claim-reward-popup.component";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { ReferralService } from "../referral-service.service";
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-claim-reward",
  templateUrl: "./claim-reward.component.html",
  styleUrls: ["./claim-reward.component.css"]
})
export class ClaimRewardComponent implements OnInit {
  slug: any;
  referrerToken: any;
  claimBtnDisabled: boolean;
  mname: any;
  rname: any;
  chainId:any;
  url:any;
  constructor(
    private navbarService: NavbarserviceService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private referralService: ReferralService
  ) {}

  ngOnInit() {
    this.navbarService.hide();
    this.claimBtnDisabled = true;
    //get all route params
    this.route.params.forEach(urlParams => {
      this.slug = urlParams["slug"];
      this.referrerToken = urlParams["auth"];
    });
    this.checkForReferralValidity();
  }

  //check for activation status of the referral
  checkForReferralValidity() {
    let params = { id: this.slug };
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.referrerToken
    });
    this.referralService
      .getReferralValidity(params, headers)
      .subscribe(data => {
        if (data.data && data.data.activated) {
          this.claimBtnDisabled = false;
          this.mname = data.data.mname;
          this.rname = data.data.rname;
          this.chainId = data.data.chain_id;
        } else {
          if(this.chainId){
            this.url= `/merchant/partner-locations/chain/${this.chainId}`;
          } else{
            this.url = `/merchant/details/${this.slug}`;
          }
          this.router.navigateByUrl(this.url).then(() => {
              let popup = {
                type: "Hey! This offer doesn't exist anymore.",
                image: "error",
                fromReferral: true
              };
              this.navbarService.openDialog(popup);
            });
        }
      });
  }

  //open user verification modal
  openUserVerification(): void {
    const dialogRef = this.dialog.open(ClaimRewardPopupComponent, {
      height: "auto",
      width: "500px",
      disableClose: true,
      data: {
        mname: this.mname,
        rname: this.rname,
        slug: this.slug,
        chainid:this.chainId,
        referrerToken: this.referrerToken
      }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
}
